import axios from "axios";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { call, put, takeLatest } from "redux-saga/effects";

import authApi from "../../../../authApi";

import { getLocalStorage } from "../../../../utils/helper";

import {
  fetchAboutStrategy,
  fetchAboutStrategySuccess,
  fetchMixerSummary,
  fetchMixerSummarySuccess,
  fetchActiveStrategies,
  fetchActiveStrategiesSuccess,
  fetchAlgoTradesActiveStrategies,
  fetchPortfoliosActiveStrategies,
  fetchPastStrategies,
  fetchAlgoTradesPastStrategies,
  fetchAlgoTradesAvailableStrategies,
  fetchPortfoliosPastStrategies,
  fetchPortfoliosAvailableStrategies,
  fetchAvailableStrategies,
  fetchAvailableStrategiesSuccess,
  fetchUsersAvailableStrategies,
  fetchUsersAvailableStrategiesSuccess,
  fetchActiveStrategyDetails,
  fetchActiveStrategyDetailsSuccess,
  setStrategyPauseResume,
  setDeployedAmount,
  setAdditionalAmount,
  fetchStrategyTradeHistory,
  fetchStrategyTradeHistorySuccess,
  fetchStrategiesList,
  fetchStrategiesListSuccess,
  fetchProMessagesCount,
  fetchProMessagesCountSuccess,
  fetchTopExpertsPublic,
  fetchTopExpertsPublicSuccess,
  fetchPastStrategyStatements,
  fetchPastStrategyStatementsSuccess,
  setAcceptedTncVersion,
  fetchProMessagesList,
  fetchProMessagesListSuccess,
  setProMessageAsRead,
  subscribeNewStrategy,
  getWalletBalanceSuccess,
  getWalletBalance,
  getCreditStatement,
  getCreditStatementSuccess,
  buyUsdtOnramp,
  getBuyUsdtOnrampStatus,
  getBuyUsdtOnrampStatusSuccess,
  getHmmSummary,
  getHmmSummarySuccess,
  setAgreementSigned,
  reloadAvailableBalance,
  strategyAdditionalAvailableBalance,
  getWalletAddress,
  getWalletAddressSuccess,
  placeMarketOrder,
  setInvestmentPerWeek,
  fetchAlgoTradesMixerSummary,
  fetchPortfoliosMixerSummary,
  fetchUnsubscribedStrategies,
} from "./action";

import { urls } from "./urls";

const ip = process.env.REACT_APP_API_URL;

function* fetchAboutStrategyReq(action) {
  const { id } = action.payload;

  const url = urls.fetchAboutStrategy(id);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchAlgoTradesMixerSummaryReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchMixerSummary(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchPortfoliosMixerSummaryReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchMixerSummary(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchMixerSummaryReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchMixerSummary(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchAlgoTradesActiveStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchActiveStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchPortfoliosActiveStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchActiveStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchActiveStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchActiveStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchAlgoTradesAvailableStrategiesReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchUsersAvailableStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchPortfoliosAvailableStrategiesReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchUsersAvailableStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchUsersAvailableStrategiesReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchUsersAvailableStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchUnsubscribedStrategiesReq(action) {
  const { filterType } = action.payload;
  const url = urls.fetchPastStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchAlgoTradesPastStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchPastStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchPortfoliosPastStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchPastStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchPastStrategiesReq(action) {
  const { filterType } = action.payload;

  const url = urls.fetchPastStrategies(filterType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchAvailableStrategiesReq(action) {
  const url = urls.fetchAvailableStrategies();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchAvailableStrategiesSuccess(response.data.data));
    }
  } catch (e) { }
}

function* fetchActiveStrategyDetailsReq(action) {
  const { id } = action.payload;

  const url = urls.fetchActiveStrategyDetails(id);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* setStrategyPauseResumeReq(action) {
  const { strategyId, flag } = action.payload;

  const url = urls.setStrategyPauseResume(strategyId, flag);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* setDeployedAmountReq(action) {
  const { strategyId, amount } = action.payload;
  const url = urls.setDeployedAmount(strategyId, amount);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* setAdditionalAmountReq(action) {
  const { strategyId, amount } = action.payload;
  const url = urls.setAdditionalAmount(strategyId, amount);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url));
  });
}

function* fetchStrategyTradeHistoryReq(action) {
  const { strategyId, size, page } = action.payload;
  const url = urls.fetchStrategyTradeHistory({ strategyId, size, page });
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchStrategyTradeHistorySuccess(response.data.data));
    }
  } catch (e) { }
}

function* fetchStrategiesListReq(action) {
  const url = urls.fetchStrategiesList();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchStrategiesListSuccess(response.data.data));
    }
  } catch (e) { }
}

function* fetchTopExpertsPublicReq() {
  const url = urls.fetchTopExpertsPublic();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchTopExpertsPublicSuccess(response.data.data));
    }
  } catch (e) { }
}

function* fetchPastStrategyStatementsReq() {
  const url = urls.fetchPastStrategyStatements();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchPastStrategyStatementsSuccess(response.data.data));
    }
  } catch (e) { }
}

function* setAcceptedTncVersionReq(action) {
  const { version } = action.payload;
  const url = urls.setAcceptedTncVersion(version);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url));
  });
}

function* fetchProMessagesCountReq() {
  const url = urls.fetchProMessagesCount();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchProMessagesCountSuccess(response.data.data));
    }
  } catch (e) { }
}

function* fetchProMessagesListReq(action) {
  const { size, page } = action.payload;
  const url = urls.fetchProMessagesList({ size, page });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* setProMessageAsReadReq(action) {
  const { messageId } = action.payload;

  const url = urls.setProMessageAsRead({ messageId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* subscribeNewStrategyReq(action) {
  const url = urls.subscribeNewStrategy();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

function* getWalletBalanceReq(action) {
  const url = urls.getWalletBalance();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(getWalletBalanceSuccess(response.data.data));
    }
  } catch (e) { }
}

function* getCreditStatementReq(action) {
  const { size, page } = action.payload;

  const url = urls.getCreditStatement({ size, page });
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(getCreditStatementSuccess(response.data.data));
    }
  } catch (e) { }
}

function* buyUsdtOnrampReq(action) {
  const isAuthenticated = getLocalStorage("isAuthenticated");

  const url = urls.buyUsdtOnramp();

  yield call(implementPromiseAction, action, function* () {
    if (isAuthenticated === "true") {
      return yield call(() => authApi.put(url, action.payload));
    } else {
      const newUrl = ip + url;
      return yield call(() => axios.put(newUrl, action.payload));
    }
  });
}

function* getBuyUsdtOnrampStatusReq(action) {
  const { id } = action.payload;
  const url = urls.getBuyUsdtOnrampStatus({ id });

  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(getBuyUsdtOnrampStatusSuccess(response.data.data));
    }
  } catch (e) { }
}

function* getHmmSummaryReq(action) {
  const url = urls.getHmmSummary();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* setAgreementSignedReq(action) {
  // const {} = action.payload;

  const url = urls.setAgreementSigned();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* reloadAvailableBalanceReq(action) {
  const url = urls.reloadAvailableBalance();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* strategyAdditionalAvailableBalanceReq(action) {
  const { keyType } = action.payload;

  const url = urls.strategyAdditionalAvailableBalance(keyType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* getWalletAddressReq(action) {
  const code = "binance";
  const baseSymbol = "usdt";
  const { keyType } = action.payload;
  const url = urls.getWalletAddress({
    code,
    baseSymbol,
    keyType,
  });

  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
  // try {
  //   const response = yield authApi.get(url);
  //   if (response?.data?.data) {
  //     yield put(getWalletAddressSuccess(response.data.data));
  //   }
  // } catch (e) {
  //   yield put(getWalletAddressSuccess(""));
  // }
}

function* placeMarketOrderReq(action) {
  const code = "binance";
  const url = urls.placeMarketOrder({ code });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action?.payload));
  });
}

function* setInvestmentPerWeekReq(action) {
  const { strategyId, weeklyInvestAmt } = action?.payload;

  const url = urls.setInvestmentPerWeek({ strategyId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() =>
      authApi.put(url, { weeklyInvestAmt: weeklyInvestAmt })
    );
  });
}

export function* mixerSaga() {
  yield takeLatest(fetchAboutStrategy, fetchAboutStrategyReq);
  yield takeLatest(fetchMixerSummary, fetchMixerSummaryReq);
  yield takeLatest(fetchAlgoTradesMixerSummary, fetchAlgoTradesMixerSummaryReq);
  yield takeLatest(fetchPortfoliosMixerSummary, fetchPortfoliosMixerSummaryReq);
  yield takeLatest(fetchActiveStrategies, fetchActiveStrategiesReq);
  yield takeLatest(
    fetchAlgoTradesActiveStrategies,
    fetchAlgoTradesActiveStrategiesReq
  );
  yield takeLatest(
    fetchPortfoliosActiveStrategies,
    fetchPortfoliosActiveStrategiesReq
  );
  yield takeLatest(fetchPastStrategies, fetchPastStrategiesReq);
  yield takeLatest(
    fetchAlgoTradesPastStrategies,
    fetchAlgoTradesPastStrategiesReq
  );
  yield takeLatest(
    fetchPortfoliosPastStrategies,
    fetchPortfoliosPastStrategiesReq
  );
  yield takeLatest(
    fetchUsersAvailableStrategies,
    fetchUsersAvailableStrategiesReq
  );

  yield takeLatest(
    fetchAlgoTradesAvailableStrategies,
    fetchAlgoTradesAvailableStrategiesReq
  );
  yield takeLatest(
    fetchPortfoliosAvailableStrategies,
    fetchPortfoliosAvailableStrategiesReq
  );
  yield takeLatest(fetchAvailableStrategies, fetchAvailableStrategiesReq);
  yield takeLatest(fetchActiveStrategyDetails, fetchActiveStrategyDetailsReq);
  yield takeLatest(setStrategyPauseResume, setStrategyPauseResumeReq);
  yield takeLatest(setDeployedAmount, setDeployedAmountReq);
  yield takeLatest(setAdditionalAmount, setAdditionalAmountReq);
  yield takeLatest(fetchStrategyTradeHistory, fetchStrategyTradeHistoryReq);
  yield takeLatest(fetchStrategiesList, fetchStrategiesListReq);
  yield takeLatest(fetchProMessagesCount, fetchProMessagesCountReq);
  yield takeLatest(fetchProMessagesList, fetchProMessagesListReq);
  yield takeLatest(setProMessageAsRead, setProMessageAsReadReq);
  yield takeLatest(fetchTopExpertsPublic, fetchTopExpertsPublicReq);
  yield takeLatest(fetchPastStrategyStatements, fetchPastStrategyStatementsReq);
  yield takeLatest(setAcceptedTncVersion, setAcceptedTncVersionReq);
  yield takeLatest(subscribeNewStrategy, subscribeNewStrategyReq);
  yield takeLatest(getWalletBalance, getWalletBalanceReq);
  yield takeLatest(getCreditStatement, getCreditStatementReq);
  yield takeLatest(buyUsdtOnramp, buyUsdtOnrampReq);
  yield takeLatest(getBuyUsdtOnrampStatus, getBuyUsdtOnrampStatusReq);

  yield takeLatest(getHmmSummary, getHmmSummaryReq);
  yield takeLatest(setAgreementSigned, setAgreementSignedReq);

  yield takeLatest(reloadAvailableBalance, reloadAvailableBalanceReq);
  yield takeLatest(
    strategyAdditionalAvailableBalance,
    strategyAdditionalAvailableBalanceReq
  );
  yield takeLatest(getWalletAddress, getWalletAddressReq);
  yield takeLatest(placeMarketOrder, placeMarketOrderReq);
  yield takeLatest(setInvestmentPerWeek, setInvestmentPerWeekReq);
  yield takeLatest(fetchUnsubscribedStrategies, fetchUnsubscribedStrategiesReq);
}
