export const SET_SIP_PLAN = "sip/SET_SIP_PLAN";
export const SET_SIP_PLAN_SUCCESS = "sip/SET_SIP_PLAN_SUCCESS";

export const GET_ASSETS_COMPARISON = "asset/GET_ASSETS_COMPARISON";
export const GET_ASSETS_COMPARISON_SUCCESS =
  "asset/GET_ASSETS_COMPARISON_SUCCESS";

export const EDIT_SIP_PLAN_FOR_STRATEGY = "sip/EDIT_SIP_PLAN_FOR_STRATEGY";
export const EDIT_SIP_PLAN_FOR_STRATEGY_SUCCESS =
  "sip/EDIT_SIP_PLAN_FOR_STRATEGY_SUCCESS";
