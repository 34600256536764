import React from "react";
import PropTypes from "prop-types";

import { ScWrapper } from "./ExtraSpace.styled";

type Props = {
  height: string | number;
  background?: string;
  extraStyle?: React.CSSProperties;
  extraClass?: string;
};
const ExtraSpace = (props : Props) => {
  const { height, background, extraStyle, extraClass } = props;
  const heightNum = parseInt(height.toString());
  return (
    <ScWrapper
      className={extraClass}
      // @ts-ignore
      height={heightNum}
      background={background}
      style={extraStyle}
    />
  );
};

ExtraSpace.propTypes = {
  height: PropTypes.string.isRequired,
  background: PropTypes.string,
  extraStyle: PropTypes.object,
  extraClass: PropTypes.string,
};

export default ExtraSpace;
