import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

const writeKey = "yBAHNEfTnr2M3pEQNEevBRtDtU31fKMk";

let analytics = null;

const initializeAnalytics = () => {
  analytics = Analytics({
    app: "Trueone",
    plugins: [
      segmentPlugin({
        writeKey: writeKey,
        disableAnonymousTraffic: true,
      }),
    ],
  });

  analytics.page();

  window.Analytics = analytics;
};

const resetAnalytics = () => {
  analytics = null;

  initializeAnalytics();
};

if(analytics === null){
  initializeAnalytics();
}

export { resetAnalytics, analytics as default };
