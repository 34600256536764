export const urls = {
  setSipPlan: () => `/api/v1/pro/sip-plan`,
  editSipPlanForStrategy: ({ strategyId }) =>
    `/api/v1/strategy/${strategyId}/sip-plan`,
  getAssetsComparison: ({
    frequency,
    undervaluationDegree,
    percentPriceDip,
    years,
  }) =>
    `/api/v1/strategy/asset/comparison?frequency=${
      frequency !== undefined ? frequency : ""
    }&undervaluationDegree=${
      undervaluationDegree !== undefined ? undervaluationDegree : ""
    }&percentPriceDip=${
      percentPriceDip !== undefined ? percentPriceDip : ""
    }&years=${years}`,
};
