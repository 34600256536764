import React from "react";

import styled from "styled-components";
import Button from "../button";
import Image from "../image";

import { CloseWhiteIcon, TickIcon } from "../../icons/data";
import { ErrorIcon } from "../../icons/data";

export const Wrapper = styled.div`
  padding: 0 15px;
  position: fixed;
  bottom: 88px;
  width: 100%;
  max-width: 620px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  .wrapper {
    padding: 15px 20px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    &.success {
      background: #01ba61;
    }
    &.error {
      background: #f7931a;
    }
    & .toast_icon {
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      display: block;
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  & .close_cta {
    min-width: 24px;
    max-width: 24px;
  }
  & .body_content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;
    .text {
      margin-bottom: 0;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
      flex: 1;
    }
    .subtext {
      margin-bottom: 0;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      flex: 1;
    }
  }
`;

const Toaster = ({
  variant,
  message,
  body = "",
  showClose = false,
  handleNotificationClose = () => {},
  handleNotificationClick = () => {},
  extraStyle,
}) => {
  return (
    <Wrapper onClick={handleNotificationClick} style={extraStyle}>
      <div className={`wrapper ${variant}`}>
        <Image
          src={variant === "success" ? TickIcon : ErrorIcon}
          alt=""
          imageClass="toast_icon"
        />

        <div className="body_content">
          <p className="text">{message}</p>
          {body !== "" ? <p className="subtext ">{body}</p> : null}
        </div>
        {showClose ? (
          <Button
            extraClass="close_cta"
            handleClick={handleNotificationClose}
            noCss={true}
          >
            <Image src={CloseWhiteIcon} alt="" />
          </Button>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Toaster;
