import {
  SET_SIP_PLAN,
  GET_ASSETS_COMPARISON,
  GET_ASSETS_COMPARISON_SUCCESS,
  EDIT_SIP_PLAN_FOR_STRATEGY,
} from "./types";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const setSipPlan = createPromiseAction(SET_SIP_PLAN);

export const getAssetsComparison = createPromiseAction(GET_ASSETS_COMPARISON);

export const getAssetsComparisonSuccess = (payload) => ({
  type: GET_ASSETS_COMPARISON_SUCCESS,
  payload,
});

export const editSipPlanForStrategy = createPromiseAction(
  EDIT_SIP_PLAN_FOR_STRATEGY
);
