import { all } from "redux-saga/effects";

import { loginSaga } from "../../components/templates/auth/store/saga";
import { membershipSaga } from "../../components/templates/membership/store/saga";
import { mixerSaga } from "../../components/templates/active-strategies/store/saga";
import { financialProfileSaga } from "../../components/templates/risk-profile/store/saga";
import { investmentAmountSaga } from "../../components/templates/investment-amount/store/saga";
import { systematicInvestingSaga } from "../../components/templates/systematic-investing/store/saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    membershipSaga(),
    mixerSaga(),
    financialProfileSaga(),
    investmentAmountSaga(),
    systematicInvestingSaga(),
  ]);
}
