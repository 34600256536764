import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import window from "global";
import { Provider } from "react-redux";

import { ThemeProvider } from "styled-components";

import store from "../src/redux/store";
import AppRoutes from "./routes";

import analytics from "./utils/segment";

import Notifications from "./components/common/notifications";

import { getLocalStorage } from "./utils/helper";

import {
  fetchUserProfile,
  fetchUserProfileSuccess,
} from "./components/templates/auth/store/action";

import theme from "./theme";
import "./App.css";
import InstallApp from "./InstallApp";

const env = process.env.REACT_APP_ENV;

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  // const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [requestForNotifications, setRequestForNotifications] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState("false");

  const [isLaunchedFromHomeScreen, setIsLaunchedFromHomeScreen] =
    useState(true);

  const [shouldHideForcedBanner, setShouldHideForcedBanner] = useState(true);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("monthly-pnl-reports")) {
      setShouldHideForcedBanner(false);
    }
  }, []);

  // Removed Forcefull app install banner
  // useEffect(() => {
  //   const userAgent = navigator.userAgent;
  //   const isMobileBrowser = /Mobi|Android/i.test(userAgent);

  //   if (isMobileBrowser) {
  //     const isStandalone =
  //       window.navigator.standalone ||
  //       window.matchMedia("(display-mode: standalone)").matches;

  //     if (isStandalone) {
  //       setIsLaunchedFromHomeScreen(true);
  //     } else {
  //       setIsLaunchedFromHomeScreen(false);
  //     }
  //   }
  // }, []);

  analytics !== null &&
    analytics.on("page", () => {
      console.log("");
    });
  analytics !== null &&
    analytics.on("pageEnd", () => {
      console.log("");
    });
  analytics !== null &&
    analytics.on("pageStart", function lol() {
      console.log("");
    });

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          setShowAddToHomeScreen(false);
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const isUserAuthenticated = getLocalStorage("isAuthenticated");
    setIsUserAuthenticated(isUserAuthenticated);
  }, []);

  const dispatchToStoreFetchUserProfile = useCallback(
    (payload) =>
      dispatch(fetchUserProfile(payload))
        .then((response) => {
          const responseData = response?.data?.data;
          dispatch(fetchUserProfileSuccess(responseData));
        })
        .catch((err) => console.log(err)),
    [dispatch]
  );

  useEffect(() => {
    if (isUserAuthenticated === "true") {
      dispatchToStoreFetchUserProfile();
    }
  }, [isUserAuthenticated]);

  const userDataLoaded = useSelector((state) => state.login?.userDataLoaded);
  const userData = useSelector((state) => state.login?.userData);

  useEffect(() => {
    if (isUserAuthenticated === "true" && userDataLoaded && userData !== null) {
      setRequestForNotifications(true);
    }
  }, [isUserAuthenticated, userData, userDataLoaded]);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className={`app_container ${isOffline ? "offline" : ""}`}>
          {requestForNotifications ? <Notifications /> : null}
          <AppRoutes />

          {!!isOffline && (
            <div className="offline_alert">
              You are currently offline. Please check your internet connection.
            </div>
          )}

          {showAddToHomeScreen && (
            <div className="add-to-home-screen-banner">
              <button onClick={handleAddToHomeScreen}>
                Add to Home Screen
              </button>
            </div>
          )}
          {!isLaunchedFromHomeScreen &&
          shouldHideForcedBanner ? (
            <InstallApp />
          ) : null}
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
