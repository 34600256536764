import { createPromiseAction } from "@adobe/redux-saga-promise";

import {
  FETCH_ALL_PLAN_DETAILS,
  FETCH_ALL_PLAN_DETAILS_SUCCESS,
  FETCH_PLAN_DETAILS,
  FETCH_PLAN_DETAILS_SUCCESS,
  FETCH_ACTIVE_MEMBERSHIP,
  FETCH_ACTIVE_MEMBERSHIP_SUCCESS,
  SET_FREE_MEMBERSHIP,
  VERIFY_BINANCE_PAYMENT,
} from "./types";

export const fetchPlanDetails = createPromiseAction(FETCH_PLAN_DETAILS);

export const fetchPlanDetailsSuccess = (payload) => ({
  type: FETCH_PLAN_DETAILS_SUCCESS,
  payload,
});

export const fetchAllPlanDetails = createPromiseAction(FETCH_ALL_PLAN_DETAILS);

export const fetchAllPlanDetailsSuccess = (payload) => ({
  type: FETCH_ALL_PLAN_DETAILS_SUCCESS,
  payload,
});

export const fetchActiveMembership = createPromiseAction(
  FETCH_ACTIVE_MEMBERSHIP
);

export const fetchActiveMembershipSuccess = (payload) => ({
  type: FETCH_ACTIVE_MEMBERSHIP_SUCCESS,
  payload,
});

export const setFreeMembership = createPromiseAction(SET_FREE_MEMBERSHIP);

export const verifyBinancePayment = createPromiseAction(VERIFY_BINANCE_PAYMENT);
