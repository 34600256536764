const IMG_DOMAIN = process.env.REACT_APP_PUBLIC_IMG_URL;

export const TrueoneLogoIcon = `${IMG_DOMAIN}/assets/trueone_svg/trueone_logo_white.svg`;

// HOME
export const MemberWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/member_white.svg`;

export const dummyGraph = `${IMG_DOMAIN}/assets/trueone_svg/graph.png`;
export const Step1Img = `${IMG_DOMAIN}/assets/trueone_svg/step1.png`;
export const Step2Img = `${IMG_DOMAIN}/assets/trueone_svg/step2.png`;
export const Step3Img = `${IMG_DOMAIN}/assets/trueone_svg/step3.png`;

export const TrueoneRoundIcon = `${IMG_DOMAIN}/assets/trueone_svg/trueone_round.svg`;
export const TrueoneSquareIcon = `${IMG_DOMAIN}/assets/trueone_svg/trueone_square.svg`;
export const DummyGraphIcon = `${IMG_DOMAIN}/assets/trueone_svg/dummy_graph.svg`;
export const HomeNavigationIcon = `${IMG_DOMAIN}/assets/trueone_svg/navigation/home.svg`;
export const CreditIcon = `${IMG_DOMAIN}/assets/trueone_svg/credit_diamond.svg`;
export const ErrorBoundaryIcon = `${IMG_DOMAIN}/assets/trueone_svg/error_boundary.svg`;
export const ErrorIcon = `${IMG_DOMAIN}/assets/trueone_svg/error_circle.svg`;
export const AddFundsIcon = `${IMG_DOMAIN}/assets/trueone_svg/add_funds.svg`;
export const AddFundsWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/add_funds_white.svg`;

export const HomeIcon = `${IMG_DOMAIN}/assets/trueone_svg/investment_deal.svg`;
export const NavigationSipIcon = `${IMG_DOMAIN}/assets/trueone_svg/navigation/sip.svg`;
export const MarketValueIcon = `${IMG_DOMAIN}/assets/trueone_svg/navigation/market_value.svg`;
export const BriefcaseWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/briefcase_white.svg`;

export const ProfileWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/profile_small_white.svg`;
export const ProfileIcon = `${IMG_DOMAIN}/assets/trueone_svg/profile_icon.svg`;
export const GraphIcon = `${IMG_DOMAIN}/assets/trueone_svg/graph_small_white.svg`;
export const BackIcon = `${IMG_DOMAIN}/assets/trueone_svg/back_arrow_white.svg`;

export const DownloadStatementIcon = `${IMG_DOMAIN}/assets/trueone_svg/download_statement_white.svg`;
export const ThreeDotMenuIcon = `${IMG_DOMAIN}/assets/trueone_svg/threedotmenu_white.svg`;

export const CloseWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/close_white.svg`;
export const CloseDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/close_dark.svg`;

export const WhatsAppicon = `${IMG_DOMAIN}/assets/trueone_svg/whatsapp_support_white.svg`;
export const MessagesIcon = `${IMG_DOMAIN}/assets/trueone_svg/mail.svg`;
export const HistoryIcon = `${IMG_DOMAIN}/assets/trueone_svg/trade_history_white.svg`;
export const LogoutIcon = `${IMG_DOMAIN}/assets/trueone_svg/logout.svg`;
export const ProfilePlusIcon = `${IMG_DOMAIN}/assets/trueone_svg/profile_plus.svg`;
export const DiamondIcon = `${IMG_DOMAIN}/assets/trueone_svg/diamond.svg`;

export const TickIcon = `${IMG_DOMAIN}/assets/trueone_svg/tick.svg`;
export const InfoIcon = `${IMG_DOMAIN}/assets/trueone_svg/info_white.svg`;

export const QuestionMarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/question_mark.svg`;
export const ShareIcon = `${IMG_DOMAIN}/assets/trueone_svg/share_white.svg`;
export const PlusCircleIcon = `${IMG_DOMAIN}/assets/trueone_svg/plus_circle.svg`;
export const ArrowNext = `${IMG_DOMAIN}/assets/trueone_svg/arrow_next.svg`;
export const ArrowNextWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/arrow_next_white.svg`;
export const ArrowNextBlueIcon = `${IMG_DOMAIN}/assets/trueone_svg/arrow_next_blue.svg`;
export const InfoDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/info.svg`;
export const HistoryDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/history.svg`;
export const ArrowIcon = `${IMG_DOMAIN}/assets/trueone_svg/arrow.svg`;
export const ReloadWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/reload_white.svg`;

export const WhatsappSquareIcon = `${IMG_DOMAIN}/assets/trueone_svg/whatsapp_square.svg`;
export const CalendarIcon = `${IMG_DOMAIN}/assets/trueone_svg/calendar.svg`;
export const CopyIcon = `${IMG_DOMAIN}/assets/trueone_svg/copy.svg`;

export const StripePayIcon = `${IMG_DOMAIN}/assets/trueone_svg/stripe.svg`;
export const PauseIcon = `${IMG_DOMAIN}/assets/trueone_svg/pause_white.svg`;

export const PlayIcon = `${IMG_DOMAIN}/assets/trueone_svg/play_white.svg`;
export const NoCallIcon = `${IMG_DOMAIN}/assets/trueone_svg/no_call.svg`;

export const ReloadIcon = `${IMG_DOMAIN}/assets/trueone_svg/reload.svg`;
export const WorkingOnItIcon = `${IMG_DOMAIN}/assets/trueone_svg/working_on_it.svg.svg`;

export const ClipboardIcon = `${IMG_DOMAIN}/assets/trueone_svg/clipboard.svg`;
export const BinanceRoundIcon = `${IMG_DOMAIN}/assets/trueone_svg/binance_round.svg`;

export const InProgressIcon = `${IMG_DOMAIN}/assets/trueone_svg/in_progress.svg`;
export const QuestionMarkCircle = `${IMG_DOMAIN}/assets/trueone_svg/question_mark_circle.svg`;

export const PlusCircleWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/plus_circle_white.svg`;
export const EditIcon = `${IMG_DOMAIN}/assets/trueone_svg/edit.svg`;
export const EditDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/edit_dark.svg`;
export const PaymentDueIcon = `${IMG_DOMAIN}/assets/trueone_svg/payment_due.svg`;
export const InfoAlertIcon = `${IMG_DOMAIN}/assets/trueone_svg/info_alert.svg`;
export const ShieldIcon = `${IMG_DOMAIN}/assets/trueone_svg/shield.svg`;
export const ReportsIcon = `${IMG_DOMAIN}/assets/trueone_svg/reports_white.svg`;
export const ReportsDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/reports_dark.svg`;
export const LockerIcon = `${IMG_DOMAIN}/assets/trueone_svg/locker.svg`;
export const LockIcon = `${IMG_DOMAIN}/assets/trueone_svg/lock.svg`;
export const CheckIcon = `${IMG_DOMAIN}/assets/trueone_svg/checkmark.svg`;
export const CheckmarkGreenIcon = `${IMG_DOMAIN}/assets/trueone_svg/checkmark_green.svg`;
export const CheckmarkDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/checkmark_dark.svg`;
export const ScanIcon = `${IMG_DOMAIN}/assets/trueone_svg/scan_icon.svg`;
export const LinkIcon = `${IMG_DOMAIN}/assets/trueone_svg/link.svg`;
export const LinkDarkIcon = `${IMG_DOMAIN}/assets/trueone_svg/link_dark.svg`;
export const ClockWhiteIcon = `${IMG_DOMAIN}/assets/trueone_svg/clock_white.svg`;
export const NoActiveInvestmentIcon = `${IMG_DOMAIN}/assets/trueone_svg/no_investment_icon.svg`;

export const CalendarWithArrowIcon = `${IMG_DOMAIN}/assets/trueone_svg/calendar_with_arrow.svg`;
export const ChevronsUpIcon = `${IMG_DOMAIN}/assets/trueone_svg/chevrons_up.svg`;

export const HedgetIcon = `${IMG_DOMAIN}/assets/trueone_svg/money-market/hedgit.svg`;
export const HsbcIcon = `${IMG_DOMAIN}/assets/trueone_svg/money-market/hsbc.svg`;
export const MerrillLynchtIcon = `${IMG_DOMAIN}/assets/trueone_svg/money-market/merill_lynch.svg`;
export const AdityaBirlaIcon = `${IMG_DOMAIN}/assets/trueone_svg/money-market/aditya_birla.svg`;
export const OlamIcon = `${IMG_DOMAIN}/assets/trueone_svg/money-market/olam.svg`;

// =============
export const NoHistoryLottie = `${IMG_DOMAIN}/assets/trueone_svg/lottie/noHistory.json`;
export const LoaderLottie = `${IMG_DOMAIN}/assets/trueone_svg/lottie/loader.json`;

// ===============
export const HelpLineImage = `${IMG_DOMAIN}/assets/trueone_svg/call.png`;

