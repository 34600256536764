export const urls = {
  login: () => "/oauth/token/v2",
  getOtp: () => `/api/v1/otp/send`,
  whitelistUserToPro: (spot, refCode) =>
    `/api/v1/subscription?accountType=${spot ? "SPOT" : "FUTURES"}&refCode=${
      refCode ? refCode : ""
    }`,
  userLogout: () => `/armor/oauth/token`,
  changePassword: () => `/api/v1/user/change-password`,
  fetchUserProfile: () => `/api/v1/userprofile`,
  updateUserProfile: () => `/api/v1/userprofile`,
  fetchCurrentOnboardingState: () => `/api/v3/subscription/onboard-state`,
  setLastOnboardingState: (state) =>
    `/api/v3/subscription/onboard-state/${state}`,
  connectExchange: ({ id, keyType }) =>
    `/api/v1/exchange/${id}/keys?keyType=${keyType}`,
  checkExchangePermission: ({ id }) => `/api/v1/exchange/${id}/key-permissions`,
  fetchActiveMembershipDetails: () => `/api/v1/membership/active`,
  loginWithTelegram: () => `/armor/login/federated/telegram`,
  isExchangeConnected: ({ code, keyType }) =>
    `/api/v1/exchanges?code=${code}&keyType=${keyType}`,

  getMembershipPayLinks: () => `/api/v1/membership/pay-links`,
  fetchCreditsCalculation: () => `/api/v1/credits/calculation`,
  setCreditsOrder: () => `/api/v1/credits/order`,
  fetchMonthlyReports: () => `/api/v1/pro/gains-summary/months`,
  fetchIndividuallyMonthlyDetailsReport: () => `/api/v1/credits/statement`,
  fetchMonthlyReportDetails: ({ monthCode }) =>
    `/api/v1/pro/gains-summary?monthCode=${monthCode}`,
};
