import styled, { keyframes } from "styled-components";

const ripple = keyframes`
  0% {
    transform: scaleX(0);
    opacity: 0.5;
    }
    20% {
    transform: scaleX(1);
    opacity: 0.3;
    }
    100% {
    transform: scaleX(1);
    opacity: 0;
    }
  `;

export const ScButtonWrapper = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #101010;
  text-align: center;
  background: #fff;
  cursor: pointer;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  border: 0 none;
  text-transform: capitalize;
  &.small {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 20px;
    font-weight: 500;
    border-radius: 8px;
  }

  &.white {
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white};
  }
  &.link {
    color: ${(props) => props.theme.colors.link};
    background: transparent;
    padding: 0;
    text-decoration: underline;
    text-align: left;
    width: auto;
    &.small {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
  &.link_white {
    color: #fff;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    text-align: left;
    width: auto;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  &.white_bordered {
    color: ${(props) => props.theme.colors.white};
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.white};
  }
  &.dark {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.black};
  }
  &.dark_bordered {
    color: ${(props) => props.theme.colors.black};
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.black};
  }

  &.orange {
    background: #ffffff;
    border: 1px solid #ff6262;
    box-shadow: 0px 8px 16px rgba(21, 84, 240, 0.1);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    color: #ff6262;
    &.fill {
      background: #ff6262;
      color: #fff;
      &.disabled {
        background: #ccc;
        pointer-events: none;
        border-color: #ccc;
      }
    }
  }
  &.yellow {
    background: linear-gradient(180deg, #fed955 0%, #feba55 100%);
    color: #1f2035;
    font-weight: 600;
  }
  &.yellowWithHover {
    background: linear-gradient(180deg, #fed955 0%, #feba55 100%);
    color: #1f2035;
    font-weight: 600;
    &:hover {
      color: #fff;
      background: #1f2035;
    }
  }

  &.ripple_active {
    &.has_ripple {
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }
    &.has_ripple:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background: #fff radial-gradient(circle, transparent 1%, #47a7f5 1%)
        center/15000%;
      transition: all 1s ease-in-out;
    }

    &.has_ripple:before {
      animation: ${ripple} 2s;
    }
  }
  &.disabled {
    background: #ccc;
    pointer-events: none;
    border-color: #ccc;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ScButtonWrapperNoCss = styled.button`
  border: 0 none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &.ripple_active {
    &.has_ripple {
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }
    &.has_ripple:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background: #fff radial-gradient(circle, transparent 1%, #47a7f5 1%)
        center/15000%;
      transition: all 1s ease-in-out;
    }

    &.has_ripple:before {
      animation: ${ripple} 2s;
    }
  }
`;

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 8px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;
