import React from "react";
import styled, { keyframes } from "styled-components";

const moveAround = keyframes`
0% {
  stroke-dashoffset: 0;
}
100% {
  stroke-dashoffset: 35vh;
}
`

const fade = keyframes`
0%, 80% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`
export const SvgStyled = styled.svg`
     #animate {
        stroke-dasharray: 35vh;
        stroke-dashoffset: 35vh;
        animation: ${moveAround} 2s linear infinite reverse;
        -webkit-animation: ${moveAround} 2s linear infinite reverse;
    }
    #tip {
        animation: ${fade} 2s linear infinite ;
        -webkit-animation: ${fade} 2s linear infinite;
    }
`;

const ArrowCurvedAnimated = () => {
  return (
    <SvgStyled
      width="182"
      height="290"
      viewBox="0 0 182 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <path d="M3 367.5C-5.5 225.5 46.5 90 124 1.5" stroke="white" strokeWidth={'3px'}/>
      <line id='tip' x1="124" y1="1" x2="100" y2="7" stroke="#ffffff" strokeWidth={'3px'} />
      <line id='tip' x1="124" y1="2." x2="122" y2="25" stroke="#ffffff" strokeWidth={'3px'} />

    </SvgStyled>
  );
};

export default ArrowCurvedAnimated;