import { takeLatest, call } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
// import axios from "axios";

// import { authIP } from "../../../../api";
import authApi from "../../../../authApi";
import { urls } from "./urls";
import { setFinancialProfile } from "./action";

function* setFinancialProfileReq(action) {
  const url = urls.setFinancialProfile();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

export function* financialProfileSaga() {
  yield takeLatest(setFinancialProfile, setFinancialProfileReq);
}
