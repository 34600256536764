import React, { useRef, useEffect } from "react";

import Image from "../image";

import {
  ScButtonWrapper,
  ScButtonWrapperNoCss,
  IconWrapper,
} from "./Button.styled";

const Button = ({
  handleClick,
  type,
  btnStyle,
  text,
  disabled,
  extraClass,
  hasRipple,
  icon: Icon,
  children,
  noCss,
  isSmall,
  extraStyle,
  name = "",
  ...rest
}) => {
  const ctaRef = useRef(null);

  const triggerRipple = () => {
    ctaRef.current?.classList?.add("ripple_active");
    setTimeout(() => {
      ctaRef.current?.classList?.remove("ripple_active");
    }, 400);
  };

  useEffect(() => {
    return () => {
      clearTimeout(ctaRef.current?.classList?.remove("ripple_active"));
    };
  }, []);

  const hasRippleFun = () => {
    if (hasRipple) {
      triggerRipple();
    } else {
      console.log();
    }
  };

  return (
    <>
      {noCss ? (
        <ScButtonWrapperNoCss
          style={extraStyle}
          onClick={() => {
            if (handleClick) {
              handleClick();
              hasRippleFun();
            }
          }}
          className={`has_ripple ${extraClass}`}
          ref={ctaRef}
          {...rest}
          type={type}
          disabled={disabled}
          name={name}
        >
          {children}
        </ScButtonWrapperNoCss>
      ) : (
        <ScButtonWrapper
          ref={ctaRef}
          style={extraStyle}
          onClick={(e) => {
            if (handleClick) {
              handleClick(e);
              hasRippleFun();
            }
          }}
          className={`has_ripple ${isSmall ? "small" : ""} ${
            extraClass ? extraClass : ""
          } ${
            btnStyle === "white"
              ? "white"
              : btnStyle === "whiteBordered"
              ? "white_bordered"
              : btnStyle === "dark"
              ? "dark"
              : btnStyle === "dark_bordered"
              ? "dark_bordered"
              : btnStyle === "orange"
              ? "orange"
              : btnStyle === "yellow"
              ? "yellow"
              : btnStyle === "link"
              ? "link"
              : btnStyle === "link_white"
              ? "link_white"
              : ""
          } ${disabled ? "disabled" : ""}`}
          {...rest}
          type={type}
          name={name}
        >
          {Icon && (
            <IconWrapper>
              <Image src={Icon} alt="" />
            </IconWrapper>
          )}
          {text}
        </ScButtonWrapper>
      )}
    </>
  );
};

export default Button;
