import axios from "axios";

import { getLocalStorage, logout, setLocalStorage } from "./utils/helper";

const ip = process.env.REACT_APP_API_URL;
export const authIP = process.env.REACT_APP_AUTH_URL;

export const rootPath = ip + "";

axios.defaults.withCredentials = false;

const api = axios.create({
  baseURL: rootPath,
});

api.interceptors.request.use(
  (req) => {
    const token = getLocalStorage("token");
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    const refreshToken = getLocalStorage("refresh_token");
    if (
      originalConfig.url !== "/oauth/token" &&
      originalConfig.url !== "/api/v1/otp/send" &&
      error.response &&
      refreshToken !== "undefined"
    ) {
      // Access Token is expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await api.post(`${authIP}/oauth/token`, {
            refresh_token: refreshToken,
            grant_type: "refresh_token",
          });
          const { access_token, refresh_token } = rs.data;
          setLocalStorage("token", access_token);
          setLocalStorage("refresh_token", refresh_token);
          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    } else if (error.response.status === 401) {
      logout();
    }
    return Promise.reject((error.response && error.response.data) || error);
  }
);

export default api;
