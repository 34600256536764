import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_CURRENT_ONBOARDING_STATE_SUCCESS,
  FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS,
  IS_EXCHANGE_CONNECTED_SUCCESS,
  GET_MEMBERSHIP_PAY_LINKS_SUCCESS,
  FETCH_CREDITS_CALCULATION_SUCCESS,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  RESET_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
} from "./types";

const initialState = {
  authenticated: null,
  role: "",
  userData: null,
  userDataLoaded: false,
  currentOnboardingStepLoaded: false,
  currentOnboardingStep: "",
  activeMembershipDetails: null,
  activeMembershipDetailsLoaded: false,
  isExchangeConnected: null,
  isExchangeConnectedLoaded: false,
  membershipPayLinks: null,
  membershipPayLinksLoaded: false,
  creditsCalculation: null,
  creditsCalculationLoaded: false,
  monthlyReports: null,
  monthlyReportsLoaded: false,
  monthlyReportDetails: null,
  monthlyReportDetailsLoaded: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action?.payload,
        role:
          action?.payload?.permissions && action?.payload?.permissions?.length
            ? "admin"
            : "user",
        authenticated: true,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        authenticated: false,
        error: action?.payload,
      };
    }

    case FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userData: action.payload,
        userDataLoaded: true,
      };
    }

    case FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS: {
      return {
        ...state,
        activeMembershipDetails: action.payload,
        activeMembershipDetailsLoaded: true,
      };
    }

    case FETCH_CURRENT_ONBOARDING_STATE_SUCCESS: {
      return {
        ...state,
        currentOnboardingStepLoaded: true,
        currentOnboardingStep: action.payload,
      };
    }

    case IS_EXCHANGE_CONNECTED_SUCCESS: {
      return {
        ...state,
        isExchangeConnectedLoaded: true,
        isExchangeConnected: action.payload,
      };
    }

    case GET_MEMBERSHIP_PAY_LINKS_SUCCESS: {
      return {
        ...state,
        membershipPayLinksLoaded: true,
        membershipPayLinks: action.payload,
      };
    }

    case FETCH_CREDITS_CALCULATION_SUCCESS: {
      return {
        ...state,
        creditsCalculation: action.payload,
        creditsCalculationLoaded: true,
      };
    }

    case FETCH_MONTHLY_REPORTS_SUCCESS: {
      return {
        ...state,
        monthlyReportsLoaded: true,
        monthlyReports: action.payload,
      };
    }
    case RESET_MONTHLY_REPORT_DETAILS: {
      return {
        ...state,
        monthlyReportDetailsLoaded: false,
        monthlyReportDetails: null,
      };
    }
    case FETCH_MONTHLY_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        monthlyReportDetailsLoaded: true,
        monthlyReportDetails: action.payload,
      };
    }

    default:
      return state;
  }
};

export default login;
