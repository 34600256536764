import { takeLatest, call, put } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

import authApi from "../../../../authApi";
import { urls } from "./urls";
import axios from "axios";

import {
  setSipPlan,
  getAssetsComparison,
  getAssetsComparisonSuccess,
  editSipPlanForStrategy,
} from "./action";

const ip = process.env.REACT_APP_API_URL;

function* setSipPlanReq(action) {
  const url = urls.setSipPlan();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

function* getAssetsComparisonReq(action) {
  const { frequency, undervaluationDegree, percentPriceDip, years } =
    action?.payload;

  const url = urls.getAssetsComparison({
    frequency,
    undervaluationDegree,
    percentPriceDip,
    years,
  });

  const newUrl = ip + url;
  try {
    const response = yield call(() => axios.get(newUrl));
    if (response?.data?.data) {
      yield put(getAssetsComparisonSuccess(response.data.data));
    }
  } catch (e) {}
}

function* editSipPlanForStrategyReq(action) {
  const { strategyId } = action.payload;
  const url = urls.editSipPlanForStrategy({ strategyId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url, action.payload));
  });
}

export function* systematicInvestingSaga() {
  yield takeLatest(setSipPlan, setSipPlanReq);
  yield takeLatest(getAssetsComparison, getAssetsComparisonReq);
  yield takeLatest(editSipPlanForStrategy, editSipPlanForStrategyReq);
}
