import axios from "axios";

import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  logout,
} from "./utils/helper";

const ip = process.env.REACT_APP_API_URL;

export const rootPath = ip + "";
export const authIP = process.env.REACT_APP_AUTH_URL;
export const authCmsIP = process.env.REACT_PUBLIC_APP_CMS_URL;

axios.defaults.withCredentials = false;

const authApi = axios.create({
  baseURL: rootPath,
});

authApi.interceptors.request.use(
  (req) => {
    const token = getLocalStorage("token");
    if (token) {
      removeLocalStorage("token");
    }
    const accessToken = token || getLocalStorage("accessToken");
    req.headers.Authorization = `Bearer ${accessToken}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
authApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //     if (error.response.status === 401) {
    //       logout();
    //     }
    return Promise.reject(error.response || error);
  }
);

authApi.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    const refreshToken = getLocalStorage("refresh_token");
    if (
      originalConfig.url !== "/oauth/token" &&
      originalConfig.url !== "/api/v1/otp/send" &&
      error &&
      refreshToken !== "undefined"
    ) {
      // Access Token is expired
      if (error.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        const osFromStorage = getLocalStorage("os");
        if (osFromStorage === "ios") {
          // eslint-disable-next-line
          webkit.messageHandlers.refreshToken.postMessage("");
        } else if (typeof Android !== "undefined") {
          // eslint-disable-next-line
          Android.refreshToken();
        } else {
          try {
            const rs = await authApi.post(`${authIP}/oauth/token`, {
              refresh_token: refreshToken,
              grant_type: "refresh_token",
            });
            const { access_token, refresh_token } = rs.data;
            setLocalStorage("accessToken", access_token);
            setLocalStorage("refresh_token", refresh_token);
            return authApi(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    } else {
      logout();
      console.log("refreshToken not found");
    }
    return Promise.reject((error.response && error.response.data) || error);
  }
);

export default authApi;
