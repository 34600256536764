import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import Image from "../image";

import { TrueoneLogoIcon } from "../../icons/data";

const ScBrandLogoLInk = styled(Link)`
  display: block;
  width: 128px;
  height: 38px;
  padding: 5px 10px;
`;
const ScBrandLogo = styled.div`
  display: block;
  width: 128px;
  height: 38px;
  padding: 5px 10px;
`;

const BrandImg = () => {
  return <Image src={TrueoneLogoIcon} alt="" />;
};

const BrandLogo = ({ noClickLogo = false, className = ''  }) => {
  return (
    <>
      {noClickLogo ? (
        <ScBrandLogo className={className}>
          <BrandImg />
        </ScBrandLogo>
      ) : (
        <ScBrandLogoLInk to="/">
          <BrandImg />
        </ScBrandLogoLInk>
      )}
    </>
  );
};

export default BrandLogo;
