import React, { Suspense, useEffect, useState } from "react";
import { Switch } from "react-router-dom";

import { routes } from "./routes";
import RouteWithSubRoutes from "./SubRoutes";
import { AuthContext } from "../context/auth";
import { useDispatch } from "react-redux";
import { getPermissions } from "../utils/helper";
import { loginSuccess } from "../components/templates/auth/store/action";
import PageLoader from "../components/common/page-loader/PageLoader";

const AppRoutes = (props) => {
  const dispatch = useDispatch();
  const existinAuth = Boolean(localStorage.getItem("isAuthenticated"));
  const [isAuthenticated, setIsAuthenticated] = useState(existinAuth);

  const setAuthentication = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const permissions = getPermissions();
    dispatch(loginSuccess({ permissions }));
  }, []);

  return (
    <Suspense fallback={<PageLoader />}>
      <AuthContext.Provider
        value={{ isAuthenticated, setIsAuthenticated: setAuthentication }}
      >
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
      </AuthContext.Provider>
    </Suspense>
  );
};
export default AppRoutes;
