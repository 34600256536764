import styled from 'styled-components';

export const ScErrorFallback = styled.div`
  background: #2a2b42;
  border: 1px solid #333943;
  border-radius: 12px;
  padding: 24px;
  & .fallback_icon {
    width: 148px;
    height: 84px;
    margin: 0 auto;
  }
  & .fallback_content {
    text-align: center;
    & h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
      margin-bottom: 12px;
    }
    & p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  ${({ type, theme }) =>
    type === 'small' &&
    `
    background: linear-gradient(100.62deg,#464762 0%,#383954 100%);
    border: 1px solid #565771;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
  & .fallback_icon {
    width: 36px;
    height: 36px;
    margin: 0;
    background: #fff;
    border-radius: 100px;
    padding: 0;
    position: relative;
    overflow: hidden;
    padding: 0;
    & svg {
      width: 36px;
      height: 36px;
      display: block;
    }
  }
  & .fallback_content {
    flex: 1;
    text-align: left;
    margin-left: 15px;
    & h2 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    & p {
      font-size: 12px;
      line-height: 16px;
    }
  }
`}
`;
