import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Image from "../image";

import { ScErrorFallback } from "./ErrorBoundaries.styled";

import { ErrorBoundaryIcon, ErrorIcon } from "../../icons/data";

const ErrorFallback = ({ type, extraStyle, error, resetErrorBoundary }) => {
  return (
    <ScErrorFallback style={extraStyle} type={type}>
      {type === "small" ? (
        <Image src={ErrorIcon} alt="" imageClass="fallback_icon" />
      ) : (
        <Image src={ErrorBoundaryIcon} alt="" imageClass="fallback_icon" />
      )}

      <div className="fallback_content">
        <h2>
          Oops! Unable to fetch data {":("} - {error}
        </h2>
        <p>Our team is working on to fix it</p>
      </div>
    </ScErrorFallback>
  );
};

const ErrorBoundaries = ({ type, children, extraStyle }) => {
  return (
    <ErrorBoundary
      fallback={<ErrorFallback extraStyle={extraStyle} type={type} />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaries;
