const theme = {
  colors: {
    black: "#0D253C",
    white: "#fff",
    theme: "#1F2035",
    theme_light: "#2A2B42",
    theme_extraLight: "#B0A1D3",
    theme_dark: "#373852",
    grey: "#9DA1AE",
    grey_text: "#60617A",
    grey_extraLight: "#AFB0C5",
    grey_bg: "#F4F5FC",
    link: "#1554F0",
    linklight: "#25C5FF",
    success_dark: "#068E4B",
    success: "#01BA61",
    success_light: "#90FE23",
    danger: "#FF7954",
    ready: "#FFEA00",
  },
  fontSizes: {
    extraSmall: "10px",
    small: "12px",
    medium: "16px",
    large: "20px",
  },
  margins: {
    extraSmall: "4px",
    small: "8px",
    xMedium: "12px",
    medium: "16px",
    large: "24px",
    extraLarge: "48px",
  },
  paddings: {
    extraSmall: "4px",
    small: "8px",
    xMedium: "12px",
    medium: "16px",
    large: "24px",
    extraLarge: "48px",
  },
  iconSize: {
    small: {
      minWidth: "16px",
      maxWidth: "16px",
      minHeight: "16px",
      maxHeight: "16px",
    },
    xMedium: {
      minWidth: "20px",
      maxWidth: "20px",
      minHeight: "20px",
      maxHeight: "20px",
    },
    medium: {
      minWidth: "24px",
      maxWidth: "24px",
      minHeight: "24px",
      maxHeight: "24px",
    },
    large: {
      minWidth: "32px",
      maxWidth: "32px",
      minHeight: "32px",
      maxHeight: "32px",
    },
    xLarge: {
      minWidth: "40px",
      maxWidth: "40px",
      minHeight: "40px",
      maxHeight: "40px",
    },
  },
  borderRadius: {
    none: "0px",
    extraSmall: "2px",
    small: "4px",
    medium: "8px",
    large: "12px",
  },
};

export default theme;
