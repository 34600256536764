import axios from "axios";

import { initializeApp } from "firebase/app";
import {
  isSupported,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";

import { getLocalStorage } from "./utils/helper";

const apiUrl = process.env.REACT_APP_API_URL;
const env = process.env.REACT_APP_ENV;

const firebaseConfig =
  env === "production"
    ? {
        apiKey: "AIzaSyDVpFQu8Taa0C3jY2lYTBHLeGks-BlGrgk",
        authDomain: "sahicoin-prod.firebaseapp.com",
        databaseURL: "https://sahicoin-prod-default-rtdb.firebaseio.com",
        projectId: "sahicoin-prod",
        storageBucket: "sahicoin-prod.appspot.com",
        messagingSenderId: "892064243911",
        appId: "1:892064243911:web:d02e559806b5da75309ee2",
        measurementId: "G-CL3FSMX1BH",
      }
    : {
        apiKey: "AIzaSyALkQHLSd8uwA_QXhFHUvncuQaIbk3KtFU",
        authDomain: "sahicoin-debug.firebaseapp.com",
        databaseURL: "https://sahicoin-debug-default-rtdb.firebaseio.com",
        projectId: "sahicoin-debug",
        storageBucket: "sahicoin-debug.appspot.com",
        messagingSenderId: "99946653141",
        appId: "1:99946653141:web:b0c7cf9c018a0d8acd895c",
        measurementId: "G-4D0PRW6P4E",
      };

export const isSupportedMessaging = await isSupported();

let messaging = null;
if (isSupportedMessaging) {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}

const dispatchToStoreSetClientTokenForMessaging = async (payload) => {
  const url = apiUrl + "/api/v1/device/register";
  const params = payload;
  const token = getLocalStorage("accessToken");

  const apiNew = axios.create({
    baseURL: apiUrl,
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    const response = await apiNew.post(url, params);
    // console.log(response.data);
  } catch (error) {
    console.log("Error occurred while fetching data.");
  }
};

const validKey =
  env === "production"
    ? `BHNLEbSB0RKMrPmOX8gpB533_GXFvU6ylK6a_VkRVrFDG0P4CG7LoweQoXlqXYO--FXMCorbRrftlkYwTrqOmUI`
    : `BE4M8uRerIGr104GZZciNcfLDCeJUOUPbcamHjjT9PAygic1UaYIJykmwKdCGgXn5kMa8ffr6tyLj4Wg5Thvzo0`;

export const requestPermission = () => {
  if (
    typeof window !== "undefined" &&
    "Notification" in window &&
    isSupportedMessaging
  ) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, {
          vapidKey: validKey,
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("Client Token: ", currentToken);
              if (typeof window !== "undefined" && window.navigator) {
                dispatchToStoreSetClientTokenForMessaging({
                  deviceId: currentToken,
                  device: "WEB",
                });
              }
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  } else {
    console.log("Browser does not support notifications.");
  }
};

if (isSupportedMessaging) {
  requestPermission();
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    isSupportedMessaging &&
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });
