import React from "react";
import styled, { keyframes } from "styled-components";

const moveAround = keyframes`
0% {
    stroke-dashoffset: 0;
}
100% {
    stroke-dashoffset: 30vh;
}
`
const fade = keyframes`
0%, 80% {
    opacity: 0;
}
100% {
    opacity: 1;
}
`

const SvgStyled = styled.svg`
    #animate {
        stroke-dasharray: 30vh;
        stroke-dashoffset: 30vh;
        animation: ${moveAround} 2s linear infinite reverse;
        -webkit-animation: ${moveAround} 2s linear infinite reverse;
    }

    #tip {
        animation: ${fade} 2s linear infinite ;
        -webkit-animation: ${fade} 2s linear infinite ;
    }
`;

const ArrowAnimated = () => {
    return (
        <SvgStyled
            width="23"
            height="241"
            viewBox="0 0 23 241"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <line x1="10" y1="0" x2="10" y2="119.5" stroke="#FFFFFF" /> */}
            <line id='animate' x1="10" y1="0" x2="10" y2="240" stroke="#FFFFFF" strokeWidth={'2px'} />
            <line id="tip" x1="10" y1="240" x2="20" y2="228" stroke="#FFFFFF" strokeWidth={'2px'} />
            <line id="tip" x1="10" y1="240" x2="0" y2="228" stroke="#FFFFFF" strokeWidth={'2px'} />


        </SvgStyled>
    );
};

export default ArrowAnimated;
