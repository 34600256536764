import { takeLatest, call, put } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import axios from "axios";

import { authIP } from "../../../../api";
import authApi from "../../../../authApi";
import { urls } from "./urls";
import {
  changePassword,
  getOtp,
  login,
  whitelistUserToPro,
  fetchUserProfile,
  updateUserProfile,
  fetchCurrentOnboardingStateSuccess,
  setLastOnboardingState,
  connectExchange,
  checkExchangePermission,
  fetchActiveMembershipDetails,
  loginWithTelegram,
  isExchangeConnected,
  getMembershipPayLinks,
  fetchCreditsCalculation,
  setCreditsOrder,
  fetchMonthlyReports,
  fetchMonthlyReportDetails,
  userLogout,
} from "./action";

import { FETCH_CURRENT_ONBOARDING_STATE } from "./types";
import { getLocalStorage } from "../../../../utils/helper";

function* loginRequest(action) {
  let params = {
    audience: "https://sahicoin.com",
  };
  const apiNew = axios.create({
    baseURL: authIP,
    withCredentials: false,
  });
  const { otp, phone_number } = action.payload;
  const encodedOtp = btoa(otp);
  const encodedPhoneNumber = btoa(phone_number);
  const url = urls.login();
  params = {
    password: encodedOtp,
    username: encodedPhoneNumber,
    grant_type: "http://sahicoin.com/oauth/grant-type/passwordless/otp",
  };

  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, params));
  });
}
function* getOtpRequest(action) {
  const { phone } = action.payload;
  const url = urls.getOtp();

  const params = {
    action: "LOGIN",
    phoneNumber: phone,
  };

  const apiNew = axios.create({
    baseURL: authIP,
    withCredentials: false,
  });

  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, params));
  });
}

function* loginWithTelegramReq(action) {
  const url = urls.loginWithTelegram();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

function* whitelistUserToProReq(action) {
  const { spot, refCode } = action.payload;

  const url = urls.whitelistUserToPro(spot, refCode);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url));
  });
}

function* changePasswordReq(action) {
  const url = urls.changePassword();
  const token = getLocalStorage("token");
  const apiNew = axios.create({
    baseURL: authIP,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, action.payload));
  });
}

function* fetchUserProfileReq(action) {
  const url = urls.fetchUserProfile();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchActiveMembershipDetailsReq(action) {
  const url = urls.fetchActiveMembershipDetails();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* isExchangeConnectedReq(action) {
  const { code, keyType } = action.payload;
  const url = urls.isExchangeConnected({ code, keyType });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* updateUserProfileReq(action) {
  const url = urls.updateUserProfile();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

function* fetchCurrentOnboardingStateReq() {
  const url = urls.fetchCurrentOnboardingState();

  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchCurrentOnboardingStateSuccess(response.data.data));
    }
  } catch (e) {}
}

function* setLastOnboardingStateReq(action) {
  const { state } = action.payload;
  const url = urls.setLastOnboardingState(state);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.put(url));
  });
}

function* connectExchangeReq(action) {
  const { id, keyType, ...rest } = action.payload?.params;
  const url = urls.connectExchange({ id, keyType });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, rest));
  });
}

function* checkExchangePermissionReq(action) {
  const { id, ...rest } = action.payload;
  const url = urls.checkExchangePermission(action.payload);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, rest));
  });
}

function* getMembershipPayLinksReq(action) {
  const url = urls.getMembershipPayLinks();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* fetchCreditsCalculationReq(action) {
  const url = urls.fetchCreditsCalculation();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* setCreditsOrderReq(action) {
  const url = urls.setCreditsOrder();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}
function* fetchMonthlyReportsReq(action) {
  const url = urls.fetchMonthlyReports();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}
function* fetchMonthlyReportDetailsReq(action) {
  const { monthCode } = action?.payload;
  const url = urls.fetchMonthlyReportDetails({ monthCode });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

function* userLogoutReq(action) {
  const url = urls.userLogout();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.delete(url));
  });
}

export function* loginSaga() {
  yield takeLatest(login, loginRequest);
  yield takeLatest(getOtp, getOtpRequest);
  yield takeLatest(changePassword, changePasswordReq);
  yield takeLatest(whitelistUserToPro, whitelistUserToProReq);
  yield takeLatest(loginWithTelegram, loginWithTelegramReq);
  yield takeLatest(fetchUserProfile, fetchUserProfileReq);
  yield takeLatest(
    fetchActiveMembershipDetails,
    fetchActiveMembershipDetailsReq
  );
  yield takeLatest(updateUserProfile, updateUserProfileReq);
  yield takeLatest(
    FETCH_CURRENT_ONBOARDING_STATE,
    fetchCurrentOnboardingStateReq
  );
  yield takeLatest(setLastOnboardingState, setLastOnboardingStateReq);
  yield takeLatest(connectExchange, connectExchangeReq);
  yield takeLatest(checkExchangePermission, checkExchangePermissionReq);
  yield takeLatest(isExchangeConnected, isExchangeConnectedReq);
  yield takeLatest(getMembershipPayLinks, getMembershipPayLinksReq);
  yield takeLatest(fetchCreditsCalculation, fetchCreditsCalculationReq);
  yield takeLatest(setCreditsOrder, setCreditsOrderReq);
  yield takeLatest(fetchMonthlyReports, fetchMonthlyReportsReq);
  yield takeLatest(fetchMonthlyReportDetails, fetchMonthlyReportDetailsReq);
  yield takeLatest(userLogout, userLogoutReq);
}
