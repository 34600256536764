// @ts-nocheck
import styled from "styled-components";


export const ScWrapper = styled.div`
  width: 100%;
  clear: both;
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  background: ${(props) =>
    props.background ? props.background : "transparent"};
`;
