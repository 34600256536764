import { createPromiseAction } from "@adobe/redux-saga-promise";

import {
  SET_INVESTMENT_AMOUNT,
  GET_TRUEONE_CREDITS,
  GET_TRUEONE_CREDITS_SUCCESS,
} from "./types";

export const setInvestmentAmount = createPromiseAction(SET_INVESTMENT_AMOUNT);

export const getTrueoneCredits = createPromiseAction(GET_TRUEONE_CREDITS);

export const getTrueoneCreditsSuccess = (payload) => ({
  type: GET_TRUEONE_CREDITS_SUCCESS,
  payload,
});
