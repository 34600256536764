import { takeLatest, put, call } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
// import axios from "axios";

// import { authIP } from "../../../../api";
import authApi from "../../../../authApi";
import { urls } from "./urls";
import {
  fetchPlanDetails,
  fetchPlanDetailsSuccess,
  fetchAllPlanDetails,
  fetchAllPlanDetailsSuccess,
  fetchActiveMembershipSuccess,
  fetchActiveMembership,
  setFreeMembership,
  verifyBinancePayment,
} from "./action";

function* fetchPlanDetailsReq(action) {
  const { code } = action.payload;
  const url = urls.fetchPlanDetails({ code });
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchPlanDetailsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchActiveMembershipReq(action) {
  const url = urls.fetchActiveMembership();

  // yield call(implementPromiseAction, action, function* () {
  //   return yield call(() => authApi.get(url));
  // });

  try {
    const response = yield authApi.get(url);
    if (response?.data) {
      yield put(fetchActiveMembershipSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchAllPlanDetailsReq(action) {
  const url = urls.fetchAllPlanDetails();
  try {
    const response = yield authApi.get(url);
    if (response?.data?.data) {
      yield put(fetchAllPlanDetailsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* setFreeMembershipReq(action) {
  const { code } = action.payload;
  const url = urls.setFreeMembership({ code });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

function* verifyBinancePaymentReq(action) {
  const { orderId } = action.payload;
  const url = urls.verifyBinancePayment({ orderId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url, action.payload));
  });
}

export function* membershipSaga() {
  yield takeLatest(fetchPlanDetails, fetchPlanDetailsReq);
  yield takeLatest(fetchAllPlanDetails, fetchAllPlanDetailsReq);
  yield takeLatest(fetchActiveMembership, fetchActiveMembershipReq);
  yield takeLatest(setFreeMembership, setFreeMembershipReq);
  yield takeLatest(verifyBinancePayment, verifyBinancePaymentReq);
}
