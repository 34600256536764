import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

import LottieFile from "../../common/lottie-file";
import BrandLogo from "../brand-logo";
import Image from "../image";

import LoaderAnimation from "../../lottie/loader.json";

import { ScPageWrapper } from "../../pages/Common.styled";

import { HomeIcon, NavigationSipIcon, MarketValueIcon } from "../../icons/data";

import ExtraSpace from "../extra-space";
import Button from "../button";

const ScPageLoader = styled.div`
  background-color: rgb(42, 43, 66);
  padding: ${(props) => props.theme.paddings.large};
  position: fixed;
  z-index: 13;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  & .pageLoader_container {
    position: absolute;
    width: 100%;
    max-width: 620px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(31, 32, 53);
  }
  & .pageLoader_animation {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  & .pageLoader_text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    padding: 0 20px;
    color: ${(props) => props.theme.colors.white};
  }
  & .dummy_headerWrapper,
  & .dummy_footerWrapper {
    z-index: 1;
    width: 100%;
    padding: 8px 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-height: 56px;
    & .dummy_footerWrapperItem {
      border-radius: 12px;
      &.active {
        background: #7887f8;
        filter: grayscale(0%);
        & .bottomNav_icon {
          opacity: 1;
        }
        & .bottomNav_text {
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
  & .dummy_headerWrapper {
    top: 0;
    background: rgb(55, 56, 82);
  }
  & .dummy_footerWrapper {
    width: calc(100% - 40px);
    bottom: 12px;
    background: #000;
    padding: 12px;
    border-radius: 12px;
    max-width: 580px;
    & .bottomNav_icon {
      margin-right: ${(props) => props.theme.margins.small};
      opacity: 0.5;
      ${({ theme }) => ({ ...theme.iconSize.xMedium })};
      display: block;
    }
    & .bottomNav_text {
      font-weight: 600;
      font-size: 12px;
      color: #ccc;
      white-space: nowrap;
    }
  }
`;

const PageLoader = ({
  text = "Loading brilliance... your future awaits!",
  hasDummyHeader = false,
  hasDummyFooter = false,
  activePageName = "",
  hasGoBack = false,
}) => {
  const history = useHistory();

  const [goBackCtaVisibility, setGoBackCtaVisibility] = useState(false);
  useEffect(() => {
    if (window?.document) {
      document.getElementsByTagName("body")[0].classList.add("overflow_h");
    }
    return () => {
      document.getElementsByTagName("body")[0].classList.remove("overflow_h");
    };
  }, []);

  useEffect(() => {
    if (hasGoBack) {
      setTimeout(() => {
        setGoBackCtaVisibility(true);
      }, 6000);
    }
  }, [hasGoBack]);

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <ScPageWrapper>
      <ScPageLoader className="flex align_center justify_center flex_column">
        {hasDummyHeader ? (
          <div className="trueoneMaxWrapper dummy_headerWrapper flex align_center justify_center">
            <BrandLogo noClickLogo={true} />
          </div>
        ) : null}
        <div className="pageLoader_container flex align_center justify_center flex_column">
          <div className="pageLoader_animation">
            <LottieFile
              media={LoaderAnimation}
              isStopped={false}
              options={{ loop: true, autoplay: true }}
            />
          </div>
          <p className="pageLoader_text">{text}</p>
          {hasGoBack && goBackCtaVisibility ? (
            <div>
              <ExtraSpace height="12px" />
              <p className="pageLoader_text">Taking too long?</p>
              <ExtraSpace height="8px" />
              <Button
                type="button"
                extraClass="small"
                handleClick={handleGoBack}
                text="Go Back"
              />
            </div>
          ) : null}
          <ExtraSpace height="80px" />
        </div>
        {hasDummyFooter ? (
          <div className="trueoneMaxWrapper dummy_footerWrapper flex align_center">
            <div
              className={`flex justify_center align_center dummy_footerWrapperItem ${
                activePageName === "algo-trade" ? "active" : ""
              }`}
              style={{ flex: 1.6, padding: "12px 16px" }}
              onClick={() => {
                const url = `/algo-trade`;
                history.push(`${url}`);
              }}
            >
              <Image src={HomeIcon} alt="" imageClass="bottomNav_icon" />

              <span className="bottomNav_text">AlgoTrade</span>
            </div>

            <div
              className={`flex justify_center align_center dummy_footerWrapperItem ${
                activePageName === "sip" ? "active" : ""
              }`}
              style={{ flex: 1, padding: "12px 16px" }}
              onClick={() => {
                const url = `/sip`;
                history.push(`${url}`);
              }}
            >
              <Image
                src={NavigationSipIcon}
                alt=""
                imageClass="bottomNav_icon"
              />

              <span className="bottomNav_text">SIP</span>
            </div>

            <div
              className={`flex justify_center align_center dummy_footerWrapperItem ${
                activePageName === "money-market" ? "active" : ""
              }`}
              style={{ flex: 1.3, padding: "12px 16px" }}
              onClick={() => {
                const url = `/money-market`;
                history.push(`${url}`);
              }}
            >
              <Image alt="" src={MarketValueIcon} imageClass="bottomNav_icon" />

              <span className="bottomNav_text">Savings</span>
            </div>
          </div>
        ) : null}
      </ScPageLoader>
    </ScPageWrapper>
  );
};

export default PageLoader;
