import { takeLatest, call } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
// import axios from "axios";

// import { authIP } from "../../../../api";
import authApi from "../../../../authApi";
import { urls } from "./urls";
import { setInvestmentAmount, getTrueoneCredits } from "./action";

function* setInvestmentAmountReq(action) {
  const { amount } = action.payload;
  const url = urls.setInvestmentAmount({ amount });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.post(url));
  });
}

function* getTrueoneCreditsReq(action) {
  const { code, amount } = action.payload;

  const url = urls.getTrueoneCredits({ code, amount });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => authApi.get(url));
  });
}

export function* investmentAmountSaga() {
  yield takeLatest(setInvestmentAmount, setInvestmentAmountReq);
  yield takeLatest(getTrueoneCredits, getTrueoneCreditsReq);
}
