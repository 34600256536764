import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Button from "../button";

import { ScPageWrapper } from "../../pages/Common.styled";

const ScWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  display: flex;
  & h2 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 200px;
    line-height: 240px;
    position: relative;
    padding: 0 2rem 0rem;
    z-index: 1;
    &:after {
      content: "";
      width: 80%;
      height: 30px;
      background: ${({ theme }) => theme.colors.white};
      opacity: 0.1;
      border-radius: 100%;
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      right: 0;
      bottom: 30px;
    }
  }
  & h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    margin: 3rem auto 0.8rem;
  }
  & p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    line-height: 20px;
  }
  & .goHome_btn {
    margin-top: 5rem;
  }

  @media (max-width: 767px) {
    & h2 {
      font-size: 80px;
      line-height: 100px;
      &:after {
        bottom: 0;
        height: 20px;
      }
    }
    & .goHome_btn {
      margin-top: 40px;
      width: 100%;
      & button {
        width: 100%;
      }
    }
  }
`;

const NotFound = () => {
  const history = useHistory();
  const handleGoToHome = () => {
    let search = window.location.search;
    history.push({
      pathname: "/",
      search: search,
    });
  };
  return (
    <ScPageWrapper>
      <ScWrapper>
        <div className="page_content_common flex justify_center">
          <h2>404</h2>

          <h3>Page not found</h3>
          <p>We&apos;re unable to find the page you&apos;re looking for</p>

          <Button
            extraClass="goHome_btn"
            type="button"
            text="Go to Homepage"
            handleClick={handleGoToHome}
          />
        </div>
      </ScWrapper>
    </ScPageWrapper>
  );
};

export default NotFound;
