import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  requestPermission,
  onMessageListener,
  isSupportedMessaging,
} from "../../../firebase";

import Toaster from "../toaster";

const Notifications = () => {
  const history = useHistory();

  const [toaster, setToaster] = useState(null);

  useEffect(() => {
    if (isSupportedMessaging) {
      requestPermission();
    }

    const unsubscribe = onMessageListener().then((payload) => {
      setToaster({
        variant: "success",
        message: `${
          payload?.data?.message !== undefined ? payload?.data?.message : ""
        }`,
        body: `${
          payload?.data?.title !== undefined ? payload?.data?.title : ""
        }`,
      });
      setTimeout(() => {
        setToaster(null);
      }, 6000);
    });
    return () => {
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  }, []);

  const handleNotificationClick = () => {
    let search = window.location.search;
    history.push({
      pathname: "/",
      search: search,
    });
  };

  return (
    <>
      {toaster && (
        <Toaster
          variant={toaster?.variant}
          message={toaster?.message}
          body={toaster?.body}
          extraStyle={{ bottom: "auto", top: "50px" }}
          handleNotificationClick={handleNotificationClick}
        />
      )}
    </>
  );
};
export default Notifications;
