import React, { lazy } from "react";

import NotFound from "../components/common/not-found";
const HomePage = lazy(() => import("../components/pages/HomePage"));
const LoginPage = lazy(() => import("../components/pages/LoginPage"));
const CompleteProfilePage = lazy(() =>
  import("../components/pages/CompleteProfilePage")
);
const SignAgreementPage = lazy(() =>
  import("../components/pages/SignAgreementPage")
);
const OnboardingPage = lazy(() => import("../components/pages/OnboardingPage"));

const PortfoliosPage = lazy(() => import("../components/pages/PortfoliosPage"));
const PortfoliosStrategyDetailsPage = lazy(() =>
  import("../components/pages/PortfoliosStrategyDetailsPage")
);
const MoneyMarketPage = lazy(() =>
  import("../components/pages/MoneyMarketPage")
);

const SipPage = lazy(() => import("../components/pages/SipPage"));
const SipStrategyDetailsPage = lazy(() =>
  import("../components/pages/SipStrategyDetailsPage"));

const OneTimeStrategyDetailsPage = lazy(() => import("../components/templates/sip/OneTimeSipStrategyDetailsPage"));

const ActiveBitcoinTradesHistoryPage = lazy(() =>
  import("../components/pages/ActiveBitcoinTradesHistoryPage")
);
// const RiskProfilePage = lazy(() =>
//   import("../components/pages/RiskProfilePage")
// );

// const EnterInvestmentAmountPage = lazy(() =>
//   import("../components/pages/EnterInvestmentAmountPage")
// );

// const SelectStrategiesPage = lazy(() =>
//   import("../components/pages/SelectStrategiesPage")
// );

const ActiveStrategyDetailsPage = lazy(() =>
  import("../components/pages/ActiveStrategyDetailsPage")
);

const HistoryPage = lazy(() => import("../components/pages/HistoryPage"));
const ReportsListPage = lazy(() =>
  import("../components/pages/ReportsListPage")
);

const ProfileSettingsPage = lazy(() =>
  import("../components/pages/ProfileSettingsPage")
);

const MonthlyPnlReportsPage = lazy(() =>
  import("../components/pages/MonthlyPnlReportsPage")
);

const NotificationsPage = lazy(() =>
  import("../components/pages/NotificationsPage")
);

const PayViaBinancePage = lazy(() =>
  import("../components/pages/PayViaBinancePage")
);
const AddUsdtToBinancePage = lazy(() =>
  import("../components/pages/AddUsdtToBinancePage")
);

const UsdtAddedSuccessfullyPage = lazy(() =>
  import("../components/pages/UsdtAddedSuccessfullyPage")
);

const GuideGenerateApiKeysPage = lazy(() =>
  import("../components/pages/GuideGenerateApiKeysPage")
);
const SystematicInvestingPage = lazy(() =>
  import("../components/pages/SystematicInvestingPage")
);

const SystematicInvestingCreatePage = lazy(() =>
  import("../components/pages/SystematicInvestingCreatePage")
);

const GuideCreateSubAccountPage = lazy(() =>
  import("../components/pages/GuideCreateSubAccountPage")
);

const GuideEnableFuturesSubAccountPage = lazy(() =>
  import("../components/pages/GuideEnableFuturesSubAccountPage")
);

const MembershipPaymentsPage = lazy(() =>
  import("../components/pages/MembershipPaymentsPage")
);

const MembershipPaymentsSuccessPage = lazy(() =>
  import("../components/pages/MembershipPaymentsSuccessPage")
);

const InvestMorePage = lazy(() =>
  import("../components/pages/InvestMorePage")
);

const SipBuyNowPage = lazy(() => import("../components/pages/SipBuyNowPage"));

const TrueoneCreditsPage = lazy(() =>
  import("../components/pages/TrueoneCreditsPage")
);

const RechargeNowPage = lazy(() =>
  import("../components/pages/RechargeNowPage")
);

const AboutCreditsPage = lazy(() =>
  import("../components/pages/AboutCreditsPage")
);

const CreditsHistoryPage = lazy(() =>
  import("../components/pages/CreditsHistoryPage")
);

const AlgoTradePage = lazy(() => import("../components/pages/AlgoTradePage"));
const AlgoTradeStrategyDetailsPage = lazy(() =>
  import("../components/pages/AlgoTradeStrategyDetailsPage")
);

const JoinStrategyPage = lazy(() =>
  import("../components/pages/JoinStrategyPage")
);

const AddFundsPage = lazy(() => import("../components/pages/AddFundsPage"));
const AddFundsSuccessPage = lazy(() =>
  import("../components/pages/AddFundsSuccessPage")
);

const UserAgreementV1 = lazy(() => import("../components/pages/UserAgreement"));

const ConnectExchange = lazy(() =>
  import("../components/templates/connect-exchange/ConnectExchange")
);

const OAuthCallback = lazy(() =>
  import("../components/templates/connect-exchange/OAuthCallback")
);

const ActivateSubAccount = lazy(() =>
  import("../components/templates/connect-exchange/ActivateSubAccount")
);

const APIPermissions = lazy(() =>
  import("../components/templates/connect-exchange/APIPermissions")
);

const ExchangeSuccessfull = lazy(() =>
  import("../components/templates/connect-exchange/ExchangeSuccessfull")
);


const NoMatch = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};

export const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/membership-payments/success",
    component: MembershipPaymentsSuccessPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/login",
    component: LoginPage,
    exact: true,
    isLogin: true,
    isPrivate: false,
  },
  {
    path: "/complete-profile",
    component: CompleteProfilePage,
    exact: true,
    isLogin: true,
    isPrivate: true,
  },
  {
    path: "/sign-agreement",
    component: SignAgreementPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/onboarding",
    component: OnboardingPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/connect-exchange",
    component: ConnectExchange,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/connect-exchange/activate-sub-account",
    component: ActivateSubAccount,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/connect-exchange/api-permissions",
    component: APIPermissions,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/connect-exchange/success",
    component: ExchangeSuccessfull,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/binance/oauth/callback",
    component: OAuthCallback,
    exact: true,
    isPrivate: true,
  },

  {
    path: "/sidenav",
    component: ProfileSettingsPage,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: "/membership-plan",
  //   component: SelectMembershipPage,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: "/membership-plan/activated",
  //   component: MembershipSuccessPage,
  //   exact: true,
  //   isPrivate: true,
  // },
  {
    path: "/money-market",
    component: MoneyMarketPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/portfolios",
    component: PortfoliosPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/portfolios-strategy-details/:strategyId",
    component: PortfoliosStrategyDetailsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/sip",
    component: SipPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/active-bitcoin-trades-history",
    component: ActiveBitcoinTradesHistoryPage,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: "/risk-profile",
  //   component: RiskProfilePage,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: "/investment-amount",
  //   component: EnterInvestmentAmountPage,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: "/select-strategies",
  //   component: SelectStrategiesPage,
  //   exact: true,
  //   isPrivate: true,
  // },

  {
    path: "/active-strategies/details/:strategyId",
    component: ActiveStrategyDetailsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/history",
    component: HistoryPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/reports-list",
    component: ReportsListPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/monthly-pnl-reports/:monthCode",
    component: MonthlyPnlReportsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/notifications",
    component: NotificationsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pay-via-binance",
    component: PayViaBinancePage,
    exact: false,
    isPrivate: false,
  },
  {
    path: "/add-usdt-to-binance",
    component: AddUsdtToBinancePage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/add-usdt-to-binance/success",
    component: UsdtAddedSuccessfullyPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/guide-generate-api-keys",
    component: GuideGenerateApiKeysPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/systematic-investing",
    component: SystematicInvestingPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/systematic-investing/create",
    component: SystematicInvestingCreatePage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/guide-create-sub-account",
    component: GuideCreateSubAccountPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/guide-enable-futures-sub-account",
    component: GuideEnableFuturesSubAccountPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/membership-payments",
    component: MembershipPaymentsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/invest-more",
    component: InvestMorePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/sip-buy-now",
    component: SipBuyNowPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/trueone-credits",
    component: TrueoneCreditsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/recharge-now",
    component: RechargeNowPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/about-credits",
    component: AboutCreditsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/credits-history",
    component: CreditsHistoryPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/algo-trade",
    component: AlgoTradePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/algo-trade-strategy-details/:strategyId",
    component: AlgoTradeStrategyDetailsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/sip-strategy-details/:strategyId",
    component: SipStrategyDetailsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/onetime-strategy-details/:strategyId",
    component: OneTimeStrategyDetailsPage,
    exact: true,
    isPrivate: true,
  },

  {
    path: "/add-funds",
    component: AddFundsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/join-strategy/:strategyId",
    component: JoinStrategyPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/add-funds/success",
    component: AddFundsSuccessPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/user-agreement/v1",
    component: UserAgreementV1,
    exact: true,
    isPrivate: false
  },
  {
    path: "/:notFound",
    component: NoMatch,
  },
];
