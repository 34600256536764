import { combineReducers } from "redux";

import login from "../../components/templates/auth/store/reducer";
import membership from "../../components/templates/membership/store/reducer";
import mixer from "../../components/templates/active-strategies/store/reducer";
import financialProfile from "../../components/templates/risk-profile/store/reducer";
import investmentAmount from "../../components/templates/investment-amount/store/reducer";
import systematicInvestingReducer from "../../components/templates/systematic-investing/store/reducer";

const rootReducer = combineReducers({
  login,
  membership,
  mixer,
  financialProfile,
  investmentAmount,
  systematicInvestingReducer,
});

export default rootReducer;
