export const urls = {
  fetchAboutStrategy: (id) => `/api/v1/strategy/${id}/info`,
  fetchMixerSummary: (filterType) =>
    `/api/v1/pro/summary?filterType=${filterType}`,
  fetchActiveStrategies: (filterType) =>
    `/api/v1/strategies/active?filterType=${filterType}`,
  fetchUnsunscribedStrategies: (filterType) => `/api/v1/strategies/unsubscribed?filterType=${filterType}`,
  fetchPastStrategies: (filterType) =>
    `/api/v1/user/strategies/unsubscribed?filterType=${filterType}`,
  fetchUsersAvailableStrategies: (filterType) =>
    `/api/v1/user/strategies/available?filterType=${filterType}`,
  fetchAvailableStrategies: () => `/api/v1/user/available-strategies`,
  fetchActiveStrategyDetails: (id) => `/api/v1/strategy/${id}/portfolio`,
  setStrategyPauseResume: (strategyId, flag) =>
    `/api/v1/strategy/${strategyId}/pause/${flag}`,
  fetchStrategiesList: () => `api/v1/user/strategies`,
  fetchStrategyTradeHistory: ({ strategyId, size, page }) =>
    `/api/v1/strategy/${strategyId}/trade-history?size=${size}&page=${page}`,
  setDeployedAmount: (strategyId, amount) =>
    `/api/v1/strategy/${strategyId}/deployed-amount?amount=${amount}`,
  setAdditionalAmount: (strategyId, amount) =>
    `/api/v1/strategy/${strategyId}/increase-amount?amount=${amount}`,
  fetchTopExpertsPublic: () => `/api/v1/strategies/top`,
  fetchPastStrategyStatements: () => `/api/v1/pro/quarter-reports`,
  setAcceptedTncVersion: (version) => `/api/v1/accept-tnc/${version}`,

  fetchProMessagesList: ({ size, page }) =>
    `/api/v1/strategy/messages?size=${size}&page=${page}`,
  fetchProMessagesCount: () => `/api/v1/strategy/messages/unread-count`,
  setProMessageAsRead: ({ messageId }) =>
    `/api/v1/strategy/message/${messageId}/acknowledge`,
  subscribeNewStrategy: () => `/api/v2/subscribe/strategies`,
  getWalletBalance: () => `/api/v1/wallet/balance`,
  getCreditStatement: ({ size, page }) =>
    `/api/v1/wallet/credits/statement?size=${size}&page=${page}`,

  buyUsdtOnramp: () => `/api/v1/onramp/buy`,
  getBuyUsdtOnrampStatus: ({ id }) => `/api/v1/onramp/order/${id}`,

  getHmmSummary: () => `/api/v1/hmm/summary`,
  setAgreementSigned: () => "/api/v1/agreement-signed",
  reloadAvailableBalance: () => "/api/v1/exchanges/refresh",
  strategyAdditionalAvailableBalance: (keyType) =>
    `/api/v1/strategy/available-balance?keyType=${keyType}`,
  getWalletAddress: ({ code, baseSymbol, keyType }) =>
    `/api/v1/exchange/${code}/deposit-address?baseSymbol=${baseSymbol}&keyType=${keyType}`,

  placeMarketOrder: ({ code }) => `/api/v1/exchange/${code}/market-order`,
  setInvestmentPerWeek: ({ strategyId }) =>
    `/api/v1/strategy/${strategyId}/sip-plan`,
};
