import React from "react";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const ScImage = styled.div`
  position: relative;
  overflow: hidden;
  & img {
    width: 100%;
    display: block;
  }
  & .lazy-load-image-background,
  & .lazy-load-image-loaded {
    width: 100%;
  }
`;

type Props = {
  src: string;
  alt?: string;
  imageClass?: string;
  extraStyle?: React.CSSProperties;
};
const Image = ({ src, alt, imageClass, extraStyle }: Props) => {
  return (
    <ScImage
      style={extraStyle}
      className={`${imageClass} flex align_center justify_center`}
    >
      <LazyLoadImage
        style={{ objectFit: "cover" }}
        effect="blur"
        width="100%"
        height="100%"
        src={src}
        alt={alt}
      />
    </ScImage>
  );
};

export default Image;
