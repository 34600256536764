import {
  CHANGE_PASSWORD,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SEND_OTP,
  WHITELIST_USER_TO_PRO,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE,
  SET_LAST_ONBOARDING_STATE,
  FETCH_CURRENT_ONBOARDING_STATE,
  FETCH_CURRENT_ONBOARDING_STATE_SUCCESS,
  CONNECT_EXCHANGE,
  CHECK_EXCHANGE_PERMISSIONS,
  FETCH_ACTIVE_MEMBERSHIP_DETAILS,
  FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS,
  SET_CLIENT_TOKEN_FOR_MESSAGING,
  LOGIN_WITH_TELEGRAM,
  LOGIN_WITH_TELEGRAM_SUCCESS,
  IS_EXCHANGE_CONNECTED,
  IS_EXCHANGE_CONNECTED_SUCCESS,
  GET_MEMBERSHIP_PAY_LINKS,
  GET_MEMBERSHIP_PAY_LINKS_SUCCESS,
  FETCH_CREDITS_CALCULATION,
  FETCH_CREDITS_CALCULATION_SUCCESS,
  SET_CREDITS_ORDER,
  SET_CREDITS_ORDER_SUCCESS,
  FETCH_MONTHLY_REPORTS,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  RESET_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
} from "./types";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const loginWithTelegram = createPromiseAction(LOGIN_WITH_TELEGRAM);

export const loginWithTelegramSuccess = (payload) => ({
  type: LOGIN_WITH_TELEGRAM_SUCCESS,
  payload,
});

export const fetchUserProfile = createPromiseAction(FETCH_USER_PROFILE);

export const fetchUserProfileSuccess = (payload) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload,
});

export const fetchActiveMembershipDetails = createPromiseAction(
  FETCH_ACTIVE_MEMBERSHIP_DETAILS
);

export const fetchActiveMembershipDetailsSuccess = (payload) => ({
  type: FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS,
  payload,
});

export const login = createPromiseAction(LOGIN);

export const getOtp = createPromiseAction(SEND_OTP);

export const whitelistUserToPro = createPromiseAction(WHITELIST_USER_TO_PRO);

export const changePassword = createPromiseAction(CHANGE_PASSWORD);

export const updateUserProfile = createPromiseAction(UPDATE_USER_PROFILE);

export const setLastOnboardingState = createPromiseAction(
  SET_LAST_ONBOARDING_STATE
);

export const fetchCurrentOnboardingState = () => ({
  type: FETCH_CURRENT_ONBOARDING_STATE,
});

export const fetchCurrentOnboardingStateSuccess = (payload) => ({
  type: FETCH_CURRENT_ONBOARDING_STATE_SUCCESS,
  payload,
});

export const connectExchange = createPromiseAction(CONNECT_EXCHANGE);

export const checkExchangePermission = createPromiseAction(
  CHECK_EXCHANGE_PERMISSIONS
);

export const isExchangeConnected = createPromiseAction(IS_EXCHANGE_CONNECTED);

export const isExchangeConnectedSuccess = (payload) => ({
  type: IS_EXCHANGE_CONNECTED_SUCCESS,
  payload,
});

export const getMembershipPayLinks = createPromiseAction(
  GET_MEMBERSHIP_PAY_LINKS
);

export const getMembershipPayLinksSuccess = (payload) => ({
  type: GET_MEMBERSHIP_PAY_LINKS_SUCCESS,
  payload,
});

export const fetchCreditsCalculation = createPromiseAction(
  FETCH_CREDITS_CALCULATION
);

export const fetchCreditsCalculationSuccess = (payload) => ({
  type: FETCH_CREDITS_CALCULATION_SUCCESS,
  payload,
});

export const setCreditsOrder = createPromiseAction(SET_CREDITS_ORDER);

export const setCreditsOrderSuccess = (payload) => ({
  type: SET_CREDITS_ORDER_SUCCESS,
  payload,
});

export const fetchMonthlyReports = createPromiseAction(FETCH_MONTHLY_REPORTS);

export const fetchMonthlyReportsSuccess = (payload) => ({
  type: FETCH_MONTHLY_REPORTS_SUCCESS,
  payload,
});

export const resetMonthlyReportDetails = (payload) => ({
  type: RESET_MONTHLY_REPORT_DETAILS,
  payload,
});

export const fetchMonthlyReportDetails = createPromiseAction(
  FETCH_MONTHLY_REPORT_DETAILS
);

export const fetchMonthlyReportDetailsSuccess = (payload) => ({
  type: FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
  payload,
});

export const userLogout = createPromiseAction(USER_LOGOUT);
