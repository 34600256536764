export const FETCH_PLAN_DETAILS = "plan/FETCH_PLAN_DETAILS";
export const FETCH_PLAN_DETAILS_SUCCESS = "plan/FETCH_PLAN_DETAILS_SUCCESS";

export const FETCH_ALL_PLAN_DETAILS = "plan/FETCH_ALL_PLAN_DETAILS";
export const FETCH_ALL_PLAN_DETAILS_SUCCESS =
  "plan/FETCH_ALL_PLAN_DETAILS_SUCCESS";

export const FETCH_ACTIVE_MEMBERSHIP = "plan/FETCH_ACTIVE_MEMBERSHIP";
export const FETCH_ACTIVE_MEMBERSHIP_SUCCESS =
  "plan/FETCH_ACTIVE_MEMBERSHIP_SUCCESS";

export const SET_FREE_MEMBERSHIP = "plan/SET_FREE_MEMBERSHIP";
export const SET_FREE_MEMBERSHIP_SUCCESS = "plan/SET_FREE_MEMBERSHIP_SUCCESS";

export const VERIFY_BINANCE_PAYMENT = "payment/VERIFY_BINANCE_PAYMENT";
export const VERIFY_BINANCE_PAYMENT_SUCCESS =
  "payment/VERIFY_BINANCE_PAYMENT_SUCCESS";
