import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from "./redux/store";
import App from "./App";
import ErrorBoundaries from "./components/common/error-boundaries";

const currentUrl = window.location.href;

// Check if the current URL contains the allowed domains
const isAllowedDomain =
  /https:\/\/app\.trueone\.ai\//.test(currentUrl) ||
  /https:\/\/uat\.trueone\.ai\//.test(currentUrl);

if (isAllowedDomain) {
  Sentry.init({
    dsn: "https://535d4ecd9b0eabe9a9509cce554d5e0c@o1020927.ingest.us.sentry.io/4506862383529984",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,

    tracePropagationTargets: [
      /https:\/\/app.trueone.ai\//,
      /https:\/\/uat.trueone.ai\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  // Sentry won't be initialized for other domains
  console.warn("Sentry not initialized. Current domain is not allowed.");
}

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered: ", registration);
      })
      .catch((error) => {
        console.log("Service Worker registration failed: ", error);
      });
  });
}

const rootElement = ReactDOM.createRoot(document.getElementById("root"));

rootElement.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary
        fallback={({ error }) => <div>An error occurred: {error.message}</div>}
      >
        <ErrorBoundaries>
          <App />
        </ErrorBoundaries>
      </ErrorBoundary>
    </Router>
  </Provider>
);
