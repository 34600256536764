// @ts-ignore
import { createPromiseAction } from "@adobe/redux-saga-promise";

import {
  FETCH_ABOUT_STRATEGY,
  FETCH_ABOUT_STRATEGY_SUCCESS,
  RESET_ABOUT_STRATEGY,
  FETCH_ALGO_TRADES_ACTIVE_STRATEGIES,
  FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_PORTFOLIOS_ACTIVE_STRATEGIES,
  FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_ACTIVE_STRATEGIES,
  FETCH_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_PAST_STRATEGIES,
  FETCH_PAST_STRATEGIES_SUCCESS,
  FETCH_ALGO_TRADES_PAST_STRATEGIES,
  FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS,
  FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES,
  FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_AVAILABLE_STRATEGIES,
  FETCH_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_USERS_AVAILABLE_STRATEGIES,
  FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_MIXER_SUMMARY,
  FETCH_MIXER_SUMMARY_SUCCESS,
  FETCH_ACTIVE_STRATEGY_DETAILS,
  FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS,
  RESET_ACTIVE_STRATEGY_DETAILS,
  SET_STRATEGY_PAUSE_RESUME,
  SET_STRATEGY_TRADE_AMOUNT,
  FETCH_STRATEGY_TRADE_HISTORY,
  FETCH_STRATEGY_TRADE_HISTORY_SUCCESS,
  FETCH_STRATEGIES_LIST,
  FETCH_STRATEGIES_LIST_SUCCESS,
  FETCH_PRO_MESSAGES_COUNT,
  FETCH_PRO_MESSAGES_COUNT_SUCCESS,
  FETCH_PRO_TOP_EXPERTS,
  FETCH_PRO_TOP_EXPERTS_SUCCESS,
  FETCH_PAST_STRATEGY_STATEMENTS,
  FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS,
  SET_ACCEPTED_VERSION_TNC,
  SET_PRO_MESSAGES_AS_READ,
  FETCH_PRO_MESSAGES_LIST,
  FETCH_PRO_MESSAGES_LIST_SUCCESS,
  SUBSCRIBE_NEW_STRATEGY,
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_SUCCESS,
  GET_CREDITS_STATEMENT,
  GET_CREDITS_STATEMENT_SUCCESS,
  BUY_USDT_ONRAMP,
  GET_BUY_USDT_ONRAMP_STATUS,
  GET_BUY_USDT_ONRAMP_STATUS_SUCCESS,
  GET_HMM_SUMMARY,
  GET_HMM_SUMMARY_SUCCESS,
  SET_AGGREEMENT_SIGNED,
  RESET_ACTIVE_STRATEGIES,
  RESET_STRATEGIES_DATA,
  RELOAD_AVAILABLE_BALANCE,
  RELOAD_AVAILABLE_BALANCE_SUCCESS,
  STRATEGY_ADDITIONAL_AVAILABLE_BALANCE,
  STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS,
  GET_WALLET_ADDRESS,
  GET_WALLET_ADDRESS_SUCCESS,
  PLACE_MARKET_ORDER,
  SET_INVESTMENT_PER_WEEK,
  FETCH_ALGO_TRADES_MIXER_SUMMARY,
  FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS,
  FETCH_PORTFOLIOS_MIXER_SUMMARY,
  FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS,
  FETCH_PORTFOLIOS_PAST_STRATEGIES,
  FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS,
  FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES,
  FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS,
  SET_STRATEGY_ADDITIONAL_AMOUNT,
  FETCH_UNSUBSCRIBED_STRATEGIES,
  FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS,
} from "./types";

export const fetchAboutStrategy = createPromiseAction(FETCH_ABOUT_STRATEGY);

export const fetchAboutStrategySuccess = (payload : any) => ({
  type: FETCH_ABOUT_STRATEGY_SUCCESS,
  payload,
});

export const resetAboutStrategy = (payload : any) => ({
  type: RESET_ABOUT_STRATEGY,
  payload,
});

export const fetchAlgoTradesMixerSummary = createPromiseAction(
  FETCH_ALGO_TRADES_MIXER_SUMMARY
);

export const fetchAlgoTradesMixerSummarySuccess = (payload : any) => ({
  type: FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS,
  payload,
});

export const fetchPortfoliosMixerSummary = createPromiseAction(
  FETCH_PORTFOLIOS_MIXER_SUMMARY
);

export const fetchPortfoliosMixerSummarySuccess = (payload : any) => ({
  type: FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS,
  payload,
});

export const fetchMixerSummary = createPromiseAction(FETCH_MIXER_SUMMARY);

export const fetchMixerSummarySuccess = (payload : any) => ({
  type: FETCH_MIXER_SUMMARY_SUCCESS,
  payload,
});

export const resetActiveStrategyies = (payload : any) => ({
  type: RESET_ACTIVE_STRATEGIES,
  payload,
});

export const resetStrategiesData = (payload : any) => ({
  type: RESET_STRATEGIES_DATA,
  payload,
});

export const fetchAlgoTradesActiveStrategies = createPromiseAction(
  FETCH_ALGO_TRADES_ACTIVE_STRATEGIES
);

export const fetchAlgoTradesActiveStrategiesSuccess = (payload : any) => ({
  type: FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchPortfoliosActiveStrategies = createPromiseAction(
  FETCH_PORTFOLIOS_ACTIVE_STRATEGIES
);

export const fetchPortfoliosActiveStrategiesSuccess = (payload : any) => ({
  type: FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchActiveStrategies = createPromiseAction(
  FETCH_ACTIVE_STRATEGIES
);

export const fetchActiveStrategiesSuccess = (payload : any) => ({
  type: FETCH_ACTIVE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchAlgoTradesPastStrategies = createPromiseAction(
  FETCH_ALGO_TRADES_PAST_STRATEGIES
);

export const fetchAlgoTradesPastStrategiesSuccess = (payload : any) => ({
  type: FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS,
  payload,
});

export const fetchUnsubscribedStrategies = createPromiseAction(
  FETCH_UNSUBSCRIBED_STRATEGIES
);

export const fetchUnsubscribedStrategiesSuccess = (payload : any) => ({
  type: FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS,
  payload,
});

export const fetchPortfoliosPastStrategies = createPromiseAction(
  FETCH_PORTFOLIOS_PAST_STRATEGIES
);

export const fetchPortfoliosPastStrategiesSuccess = (payload : any) => ({
  type: FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS,
  payload,
});

export const fetchPastStrategies = createPromiseAction(FETCH_PAST_STRATEGIES);

export const fetchPastStrategiesSuccess = (payload : any) => ({
  type: FETCH_PAST_STRATEGIES_SUCCESS,
  payload,
});
export const fetchAlgoTradesAvailableStrategies = createPromiseAction(
  FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES
);

export const fetchAlgoTradesAvailableStrategiesSuccess = (payload : any) => ({
  type: FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchPortfoliosAvailableStrategies = createPromiseAction(
  FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES
);

export const fetchPortfoliosAvailableStrategiesSuccess = (payload : any) => ({
  type: FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchUsersAvailableStrategies = createPromiseAction(
  FETCH_USERS_AVAILABLE_STRATEGIES
);

export const fetchUsersAvailableStrategiesSuccess = (payload : any) => ({
  type: FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS,
  payload,
});

export const fetchAvailableStrategies = createPromiseAction(
  FETCH_AVAILABLE_STRATEGIES
);

export const fetchAvailableStrategiesSuccess = (payload : any) => ({
  type: FETCH_AVAILABLE_STRATEGIES_SUCCESS,
  payload,
});
export const resetActiveStrategyDetails = (payload : any) => ({
  type: RESET_ACTIVE_STRATEGY_DETAILS,
  payload,
});

export const fetchActiveStrategyDetails = createPromiseAction(
  FETCH_ACTIVE_STRATEGY_DETAILS
);

export const fetchActiveStrategyDetailsSuccess = (payload : any) => ({
  type: FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS,
  payload,
});

export const setStrategyPauseResume = createPromiseAction(
  SET_STRATEGY_PAUSE_RESUME
);

export const setDeployedAmount = createPromiseAction(SET_STRATEGY_TRADE_AMOUNT);

export const setAdditionalAmount = createPromiseAction(SET_STRATEGY_ADDITIONAL_AMOUNT);

export const fetchStrategyTradeHistory = createPromiseAction(
  FETCH_STRATEGY_TRADE_HISTORY
);

export const fetchStrategyTradeHistorySuccess = (payload : any) => ({
  type: FETCH_STRATEGY_TRADE_HISTORY_SUCCESS,
  payload,
});

export const fetchStrategiesList = createPromiseAction(FETCH_STRATEGIES_LIST);

export const fetchStrategiesListSuccess = (payload : any) => ({
  type: FETCH_STRATEGIES_LIST_SUCCESS,
  payload,
});

export const fetchTopExpertsPublic = createPromiseAction(FETCH_PRO_TOP_EXPERTS);

export const fetchTopExpertsPublicSuccess = (payload : any) => ({
  type: FETCH_PRO_TOP_EXPERTS_SUCCESS,
  payload,
});

export const fetchPastStrategyStatements = createPromiseAction(
  FETCH_PAST_STRATEGY_STATEMENTS
);

export const fetchPastStrategyStatementsSuccess = (payload : any) => ({
  type: FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS,
  payload,
});

export const setAcceptedTncVersion = createPromiseAction(
  SET_ACCEPTED_VERSION_TNC
);

export const fetchProMessagesCount = createPromiseAction(
  FETCH_PRO_MESSAGES_COUNT
);

export const fetchProMessagesCountSuccess = (payload : any) => ({
  type: FETCH_PRO_MESSAGES_COUNT_SUCCESS,
  payload,
});

export const fetchProMessagesList = createPromiseAction(
  FETCH_PRO_MESSAGES_LIST
);

export const fetchProMessagesListSuccess = (payload : any) => ({
  type: FETCH_PRO_MESSAGES_LIST_SUCCESS,
  payload,
});

export const setProMessageAsRead = createPromiseAction(
  SET_PRO_MESSAGES_AS_READ
);

export const subscribeNewStrategy = createPromiseAction(SUBSCRIBE_NEW_STRATEGY);

export const getWalletBalance = createPromiseAction(GET_WALLET_BALANCE);

export const getWalletBalanceSuccess = (payload : any) => ({
  type: GET_WALLET_BALANCE_SUCCESS,
  payload,
});

export const getCreditStatement = createPromiseAction(GET_CREDITS_STATEMENT);

export const getCreditStatementSuccess = (payload : any) => ({
  type: GET_CREDITS_STATEMENT_SUCCESS,
  payload,
});

export const buyUsdtOnramp = createPromiseAction(BUY_USDT_ONRAMP);

export const getBuyUsdtOnrampStatus = createPromiseAction(
  GET_BUY_USDT_ONRAMP_STATUS
);

export const getBuyUsdtOnrampStatusSuccess = (payload : any) => ({
  type: GET_BUY_USDT_ONRAMP_STATUS_SUCCESS,
  payload,
});
export const getWalletAddress = createPromiseAction(GET_WALLET_ADDRESS);

export const getWalletAddressSuccess = (payload : any) => ({
  type: GET_WALLET_ADDRESS_SUCCESS,
  payload,
});

export const getHmmSummary = createPromiseAction(GET_HMM_SUMMARY);

export const getHmmSummarySuccess = (payload : any) => ({
  type: GET_HMM_SUMMARY_SUCCESS,
  payload,
});

export const setAgreementSigned = createPromiseAction(SET_AGGREEMENT_SIGNED);

export const reloadAvailableBalance = createPromiseAction(
  RELOAD_AVAILABLE_BALANCE
);

export const reloadAvailableBalanceSuccess = (payload : any) => ({
  type: RELOAD_AVAILABLE_BALANCE_SUCCESS,
  payload,
});

export const strategyAdditionalAvailableBalance = createPromiseAction(
  STRATEGY_ADDITIONAL_AVAILABLE_BALANCE
);

export const strategyAdditionalAvailableBalanceSuccess = (payload : any) => ({
  type: STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS,
  payload,
});

export const placeMarketOrder = createPromiseAction(PLACE_MARKET_ORDER);

export const setInvestmentPerWeek = createPromiseAction(
  SET_INVESTMENT_PER_WEEK
);
