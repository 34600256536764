import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Image from "./components/common/image";

import {
  ThreeDotMenuIcon,
  TrueoneSquareIcon,
  ShareIcon,

} from "../src/components/icons/data";
import ArrowAnimated from "./components/icons/ArrowAnimated";
import ArrowCurvedAnimated from "./components/icons/ArrowCurvedAnimated";

const ScInstallApp = styled.div`
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .installApp_browserImg {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 0;
    & img {
      width: auto;
    }
    &.android {
      top: 10px;
      transform: translateX(50%) 
    }
    &.ios {
      bottom: 20px;
      height: 30vh !important;
      left: 50%;
      transform: translateX(-50%);
    }
    
  }
  & .installApp_contentWrapper {
    padding: 40px 20px 40px 20px;
    border-radius: 6px;
    background: #fff;
    flex-shrink: 0;
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    z-index: 1;
    & .installApp_icon {
      & img {
        width: 70px;
        height: 70px;
      }
    }
    & .installApp_text {
      font-size: 28px;
      line-height: 26px;
      font-weight: 500;
      text-align: center;
      color: #333;
      margin: 16px auto 1px;
    }
    & .installApp_subText {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-align: center;
      color: #666;
    }
  }
  & .installApp_hintWrapper {
    margin-top: 30px;
    & .installApp_hintText {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      text-align: left;
      color: #666;
      padding: 0 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & span {
        font-weight: 500;
      }
      & .installApp_hintIcon {
        width: 24px;
        height: 24px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: #333;
        border-radius: 100px;
        overflow: hidden;
        margin: 0 8px;
        & svg {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
`;

const InstallApp = () => {
  const [deviceType, setDeviceType] = useState("");
  const [loadingDevice, setLoadingDevice] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobileBrowser = /Mobi|Android/i.test(userAgent);

    if (isMobileBrowser) {
      // Identify the browser type
      let browserType = "Unknown";

      if (/android/i.test(userAgent)) {
        browserType = "android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Check for Safari or Chrome on iOS
        if (/CriOS/.test(userAgent)) {
          browserType = "chrome-iOS";
        } else if (/Safari/.test(userAgent)) {
          browserType = "safari-iOS";
        } else {
          browserType = "iOS-iPhoneiPad";
        }
      } else if (/Chrome/.test(userAgent)) {
        browserType = "chrome";
      }

      // Output the information
      setDeviceType(`${browserType}`);
      setLoadingDevice(false);
    } else {
      console.log("Not a mobile browser.");
    }
  }, []);

  return (
    <ScInstallApp>
      <div className="installApp_contentWrapper">
        <Image
          src={TrueoneSquareIcon}
          alt=""
          imageClass="installApp_icon shrink_0"
        />

        {/* <p className="installApp_text"> </p> */}
        <p className="installApp_text"> TrueOne WebApp</p>

        {!loadingDevice && deviceType !== "" ? (
          <div className="installApp_hintWrapper">
            {deviceType === "android" ? (
              <>
                <p className="installApp_hintText">
                  1. Tap the three dots.{" "}
                  <Image
                    src={ThreeDotMenuIcon}
                    alt=""
                    imageClass="installApp_hintIcon"
                  />{" "}
                  (top-right)
                </p>
                <p className="installApp_hintText">
                  2. Tap  &nbsp; <span>"Add to Home Screen" </span>  &nbsp; /  &nbsp; <span>"Install App"</span>.
                 </p>
                <p className="installApp_hintText">
                  3. Open Trueone App from app gallery.
                </p>
              </>
            ) : deviceType === "chrome-iOS" ? (
              <>
                <p className="installApp_hintText">
                  1. Tap the share icon.
                  <Image
                    src={ShareIcon}
                    alt=""
                    imageClass="installApp_hintIcon"
                  />
                  (top-right)
                </p>
                <p className="installApp_hintText">
                  2. Select  &nbsp; <span>"Add to Home Screen"</span>.
                </p>
                <p className="installApp_hintText">
                  3. Open Trueone App from app gallery.
                </p>
              </>
            ) : deviceType === "safari-iOS" ? (
              <>
                <p className="installApp_hintText">
                  1. Tap the share icon.{" "}
                  <Image
                    src={ShareIcon}
                    alt=""
                    imageClass="installApp_hintIcon"
                  />{" "}
                  (bottom-center)
                </p>
                <p className="installApp_hintText">
                  2. Choose &nbsp; <span>"Add to Home Screen"</span>.
                </p>
                <p className="installApp_hintText">
                  3. Open Trueone App from app gallery.
                </p>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      {!loadingDevice && deviceType !== "" ? (
        <>
          {deviceType === "android" ? (
            <div className="installApp_browserImg android">
              <ArrowCurvedAnimated />
            </div>
          ) : deviceType === "chrome-iOS" ? (
            <div className="installApp_browserImg android">
              <ArrowCurvedAnimated />
            </div>
          ) : deviceType === "safari-iOS" ? (
            <div className="installApp_browserImg ios">
              <ArrowAnimated />
            </div>
          ) : null}
        </>
      ) : null}
    </ScInstallApp>
  );
};

export default InstallApp;
