export const FETCH_ABOUT_STRATEGY = "mixer/FETCH_ABOUT_STRATEGY";
export const FETCH_ABOUT_STRATEGY_SUCCESS =
  "mixer/FETCH_ABOUT_STRATEGY_SUCCESS";
export const RESET_ABOUT_STRATEGY = "mixer/RESET_ABOUT_STRATEGY";

export const FETCH_MIXER_SUMMARY = "mixer/FETCH_MIXER_SUMMARY";
export const FETCH_MIXER_SUMMARY_SUCCESS = "mixer/FETCH_MIXER_SUMMARY_SUCCESS";

export const FETCH_ACTIVE_STRATEGIES = "mixer/FETCH_ACTIVE_STRATEGIES";
export const FETCH_ACTIVE_STRATEGIES_SUCCESS =
  "mixer/FETCH_ACTIVE_STRATEGIES_SUCCESS";

export const FETCH_PAST_STRATEGIES = "mixer/FETCH_PAST_STRATEGIES";
export const FETCH_PAST_STRATEGIES_SUCCESS =
  "mixer/FETCH_PAST_STRATEGIES_SUCCESS";

export const FETCH_USERS_AVAILABLE_STRATEGIES =
  "mixer/FETCH_USERS_AVAILABLE_STRATEGIES";
export const FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS =
  "mixer/FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS";

export const FETCH_UNSUBSCRIBED_STRATEGIES = "mixer/FETCH_UNSUBSCRIBED_STRATEGIES";
export const FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS = "mixer/FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS";

export const FETCH_AVAILABLE_STRATEGIES = "mixer/FETCH_AVAILABLE_STRATEGIES";
export const FETCH_AVAILABLE_STRATEGIES_SUCCESS =
  "mixer/FETCH_AVAILABLE_STRATEGIES_SUCCESS";

export const FETCH_ACTIVE_STRATEGY_DETAILS =
  "mixer/FETCH_ACTIVE_STRATEGY_DETAILS";
export const FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS =
  "mixer/FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS";

export const RESET_ACTIVE_STRATEGY_DETAILS =
  "mixer/RESET_ACTIVE_STRATEGY_DETAILS";

export const RESET_ACTIVE_STRATEGIES = "mixer/RESET_ACTIVE_STRATEGIES";

export const RESET_STRATEGIES_DATA = "mixer/RESET_STRATEGIES_DATA";

export const RESET_SUBSCRIPTION_INFO = "mixer/RESET_SUBSCRIPTION_INFO";

export const SET_STRATEGY_PAUSE_RESUME = "mixer/SET_STRATEGY_PAUSE_RESUME";
export const SET_STRATEGY_PAUSE_RESUME_SUCCESS =
  "mixer/SET_STRATEGY_PAUSE_RESUME_SUCCESS";

export const SUBSCRIBE_STRATEGY = "mixer/SUBSCRIBE_STRATEGY";
export const SUBSCRIBE_STRATEGY_SUCCESS = "mixer/SUBSCRIBE_STRATEGY_SUCCESS";

export const SET_STRATEGY_TRADE_AMOUNT = "mixer/SET_STRATEGY_TRADE_AMOUNT";
export const SET_STRATEGY_TRADE_AMOUNT_SUCCESS =
  "mixer/SET_STRATEGY_TRADE_AMOUNT_SUCCESS";

export const SET_STRATEGY_ADDITIONAL_AMOUNT = "mixer/SET_STRATEGY_ADDITIONAL_AMOUNT";
export const SET_STRATEGY_ADDITIONAL_AMOUNT_SUCCESS = "mixer/SET_STRATEGY_ADDITIONAL_AMOUNT_SUCCESS";

export const FETCH_STRATEGY_TRADE_HISTORY =
  "mixer/FETCH_STRATEGY_TRADE_HISTORY";
export const FETCH_STRATEGY_TRADE_HISTORY_SUCCESS =
  "mixer/FETCH_STRATEGY_TRADE_HISTORY_SUCCESS";

export const FETCH_STRATEGIES_LIST = "mixer/FETCH_STRATEGIES_LIST";
export const FETCH_STRATEGIES_LIST_SUCCESS =
  "mixer/FETCH_STRATEGIES_LIST_SUCCESS";

export const FETCH_PRO_TOP_EXPERTS = "mixer/FETCH_PRO_TOP_EXPERTS";
export const FETCH_PRO_TOP_EXPERTS_SUCCESS =
  "mixer/FETCH_PRO_TOP_EXPERTS_SUCCESS";

export const FETCH_PAST_STRATEGY_STATEMENTS =
  "mixer/FETCH_PAST_STRATEGY_STATEMENTS";
export const FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS =
  "mixer/FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS";

export const SET_ACCEPTED_VERSION_TNC = "mixer/SET_ACCEPTED_VERSION_TNC";

export const FETCH_PRO_MESSAGES_COUNT = "pro/FETCH_PRO_MESSAGES_COUNT";
export const FETCH_PRO_MESSAGES_COUNT_SUCCESS =
  "pro/FETCH_PRO_MESSAGES_COUNT_SUCCESS";
export const FETCH_PRO_MESSAGES_LIST = "pro/FETCH_PRO_MESSAGES_LIST";
export const FETCH_PRO_MESSAGES_LIST_SUCCESS =
  "pro/FETCH_PRO_MESSAGES_LIST_SUCCESS";
export const SET_PRO_MESSAGES_AS_READ = "pro/SET_PRO_MESSAGES_AS_READ";
export const SET_PRO_MESSAGES_AS_READ_SUCCESS =
  "pro/SET_PRO_MESSAGES_AS_READ_SUCCESS";

export const SUBSCRIBE_NEW_STRATEGY = "mixer/SUBSCRIBE_NEW_STRATEGY";
export const SUBSCRIBE_NEW_STRATEGY_SUCCESS =
  "mixer/SUBSCRIBE_NEW_STRATEGY_SUCCESS";

export const GET_WALLET_BALANCE = "mixer/GET_WALLET_BALANCE";
export const GET_WALLET_BALANCE_SUCCESS = "mixer/GET_WALLET_BALANCE_SUCCESS";

export const GET_CREDITS_STATEMENT = "mixer/GET_CREDITS_STATEMENT";
export const GET_CREDITS_STATEMENT_SUCCESS =
  "mixer/GET_CREDITS_STATEMENT_SUCCESS";

export const BUY_USDT_ONRAMP = "onramp/BUY_USDT_ONRAMP";
export const BUY_USDT_ONRAMP_SUCCESS = "onramp/BUY_USDT_ONRAMP_SUCCESS";

export const GET_BUY_USDT_ONRAMP_STATUS = "onramp/GET_BUY_USDT_ONRAMP_STATUS";
export const GET_BUY_USDT_ONRAMP_STATUS_SUCCESS =
  "onramp/GET_BUY_USDT_ONRAMP_STATUS_SUCCESS";

export const GET_HMM_SUMMARY = "hmm/GET_HMM_SUMMARY";
export const GET_HMM_SUMMARY_SUCCESS = "hmm/GET_HMM_SUMMARY_SUCCESS";

export const SET_AGGREEMENT_SIGNED = "hmm/SET_AGGREEMENT_SIGNED";
export const SET_AGGREEMENT_SIGNED_SUCCESS =
  "hmm/SET_AGGREEMENT_SIGNED_SUCCESS";

export const RELOAD_AVAILABLE_BALANCE = "reload/RELOAD_AVAILABLE_BALANCE";
export const RELOAD_AVAILABLE_BALANCE_SUCCESS =
  "reload/RELOAD_AVAILABLE_BALANCE_SUCCESS";

export const STRATEGY_ADDITIONAL_AVAILABLE_BALANCE =
  "balance/STRATEGY_ADDITIONAL_AVAILABLE_BALANCE";
export const STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS =
  "balance/STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS";

export const GET_WALLET_ADDRESS = "wallet/GET_WALLET_ADDRESS";
export const GET_WALLET_ADDRESS_SUCCESS = "wallet/GET_WALLET_ADDRESS_SUCCESS";

export const PLACE_MARKET_ORDER = "order/PLACE_MARKET_ORDER";
export const SET_INVESTMENT_PER_WEEK = "amount/SET_INVESTMENT_PER_WEEK";

export const FETCH_ALGO_TRADES_ACTIVE_STRATEGIES =
  "algotrades/FETCH_ALGO_TRADES_ACTIVE_STRATEGIES";
export const FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS =
  "algotrades/FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS";

export const FETCH_ALGO_TRADES_PAST_STRATEGIES =
  "algotrades/FETCH_ALGO_TRADES_PAST_STRATEGIES";
export const FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS =
  "algotrades/FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS";

export const FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES =
  "algotrades/FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES";
export const FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS =
  "algotrades/FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS";

export const FETCH_ALGO_TRADES_MIXER_SUMMARY =
  "algotrades/FETCH_ALGO_TRADES_MIXER_SUMMARY";
export const FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS =
  "algotrades/FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS";

export const FETCH_PORTFOLIOS_ACTIVE_STRATEGIES =
  "portfolios/FETCH_PORTFOLIOS_ACTIVE_STRATEGIES";
export const FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS =
  "portfolios/FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS";

export const FETCH_PORTFOLIOS_PAST_STRATEGIES =
  "portfolios/FETCH_PORTFOLIOS_PAST_STRATEGIES";
export const FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS =
  "portfolios/FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS";

export const FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES =
  "portfolios/FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES";
export const FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS =
  "portfolios/FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS";

export const FETCH_PORTFOLIOS_MIXER_SUMMARY =
  "portfolios/FETCH_PORTFOLIOS_MIXER_SUMMARY";
export const FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS =
  "portfolios/FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS";
