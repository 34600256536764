import {
  FETCH_PLAN_DETAILS_SUCCESS,
  FETCH_ALL_PLAN_DETAILS_SUCCESS,
  FETCH_ACTIVE_MEMBERSHIP_SUCCESS,
} from "./types";

const initialState = {
  planDetails: null,
  planDetailsLoaded: false,
  allPlanDetails: null,
  allPlanDetailsLoaded: false,
  activeMembership: null,
  activeMembershipLoaded: false,
};

const membership = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_DETAILS_SUCCESS: {
      return {
        ...state,
        planDetails: action.payload,
        planDetailsLoaded: true,
      };
    }
    case FETCH_ALL_PLAN_DETAILS_SUCCESS: {
      return {
        ...state,
        allPlanDetails: action.payload,
        allPlanDetailsLoaded: true,
      };
    }

    case FETCH_ACTIVE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        activeMembership: action.payload,
        activeMembershipLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default membership;
