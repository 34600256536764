import styled from "styled-components";

export const ScPageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.theme};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & .page_content_common {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &.FORTUNER_wrapper {
      padding-top: 0px;
    }
    &.withBottomNav {
      padding-bottom: 90px;
    }
    & .bottomFix_ctaWrapper {
      position: fixed;
      bottom: 0;
      left: 50%;
      max-width: 620px;
      transform: translateX(-50%);
      width: 100%;
      z-index: 2;
      padding: ${(props) => props.theme.paddings.medium};
      background-color: ${(props) => props.theme.colors.theme_dark};
      &.withTopBorder {
        border-top: 1px solid ${(props) => props.theme.colors.grey_extraLight};
      }
      &.keyboard_Open {
      }
      &.removeFix {
        position: relative;
      }
      & .bottomFix_text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props.theme.colors.grey};
        text-align: center;
        margin-bottom: ${(props) => props.theme.margins.medium};
      }
    }
    .iframe_container {
      position: relative;
      width: 100%;
      background-color: ${(props) => props.theme.colors.theme};
      padding-bottom: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    & .input_wrapper {
      margin-bottom: ${(props) => props.theme.margins.large};
      &.withFlex {
        & .input_flex {
          flex: 1;
          margin-left: ${(props) => props.theme.margins.medium};
        }
      }
    }

    & .dark_textAlert {
      padding: ${(props) => props.theme.paddings.small}
        ${(props) => props.theme.paddings.large};
      border-radius: 20px;
      font-size: 12px;
      line-height: 18px;
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.black};
      text-align: center;
      position: fixed;
      z-index: 3;
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      white-space: nowrap;
    }

    & .tabs_wrapperCommon {
      & .react-tabs__tab-list {
        display: flex;
        & .react-tabs__tab {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          list-style: none;
          position: relative;
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.grey_extraLight};
          padding: ${(props) => props.theme.paddings.small} 0;
          margin-right: ${(props) => props.theme.paddings.large};
          &:focus-visible {
            outline: none;
          }
          &.react-tabs__tab--selected {
            color: ${(props) => props.theme.colors.white};
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              width: 80%;
              height: 2px;
              background: ${(props) => props.theme.colors.white};
              left: 50%;
              transform: translate(-50%);
            }
          }
          &.hidden {
            display: none;
          }
        }
      }
      & .react-tabs__tab-panel {
        &.react-tabs__tab-panel--selected {
          padding-top: ${(props) => props.theme.paddings.large};
        }
      }
    }
    & .pageContent_common__heading {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.white};
      &.dark {
        color: ${(props) => props.theme.colors.black};
      }
      &.large {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }
      &.center {
        text-align: center;
      }
    }
  }
  & .systematicInvesting_card__inputWrapper {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    border-radius: 4px;
    & .systematicInvesting_card__curr {
      font-size: 16px;
      line-height: 36px;
      height: 60px;
      font-weight: 600;
      padding: 12px 5px 12px 20px;
    }
    & .systematicInvesting_card__input {
      flex: 1;
      font-size: 16px;
      line-height: 22px;
      padding: 12px 20px 12px 0;
      height: 60px;
      width: 100%;
      font-weight: 600;
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
  & .systematicInvesting_cta {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    position: relative;
    border-radius: 50px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    overflow: hidden;
    &:hover {
      opacity: 0.9;
    }
    &.full {
      width: 100%;
    }
    &.white {
      background: #fff;
      border: 1px solid #000;
    }
    &.yellow {
      background: #ffc656;
      border: 1px solid #ffc656;
    }
    &.icon_left {
      padding: 8px 22px 8px 66px;
    }
    &.icon_right {
      padding: 8px 56px 8px 22px;
    }
    & .text {
      color: #000;
      font-size: 18px;
      line-height: 24px;
    }
    & .icon {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #1f2035;
      position: absolute;
      padding: 10px;
    }
    &.icon_left {
      & .icon {
        left: 8px;
      }
    }
    &.icon_right {
      & .icon {
        right: 8px;
      }
    }
  }
  & .systematicInvesting_card__secondary {
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #333;
    padding: 13px 20px;
    text-align: center;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 4px;
    &.full {
      width: 100%;
    }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .full_width {
    width: 100%;
  }
  .flex {
    display: flex;
  }
  .flex_1 {
    flex: 1;
  }
  .flex_1__4 {
    flex: 1.4;
  }
  .shrink_0 {
    flex-shrink: 0;
  }
  .flex_column {
    flex-direction: column;
  }
  .align_center {
    align-items: center;
  }
  .align_start {
    align-items: flex-start;
  }
  .align_end {
    align-items: flex-end;
  }
  .justify_center {
    justify-content: center;
  }
  .justify_start {
    justify-content: flex-start;
  }
  .justify_end {
    justify-content: flex-end;
  }
  .justify_space__between {
    justify-content: space-between;
  }
  .flex_wrap {
    flex-wrap: wrap;
  }
  .text_right {
    text-align: right;
  }
`;

export const CustomSelectOption = styled.div`
  display: flex;
  align-items: center;
  & .img {
    width: 20px;
    font-size: 20px;
    margin-right: 8px;
  }
  & .isd {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
    margin-right: 10px;
  }
  & .option_name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
  }
  @media screen and (max-width: 767px) {
    & .img {
      width: 16px;
      font-size: 16px;
    }
    & .isd {
      font-size: 16px;
      line-height: 24px;
      color: #2a2b42;
    }
    & .option_name {
      font-size: 16px;
      line-height: 24px;
      color: #2a2b42;
    }
  }
`;
