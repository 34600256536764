import { GET_TRUEONE_CREDITS_SUCCESS } from "./types";

const initialState = {
  trueoneCreditsLoaded: false,
  trueoneCredits: null,
};

const investmentAmount = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRUEONE_CREDITS_SUCCESS: {
      return {
        ...state,
        trueoneCreditsLoaded: true,
        trueoneCredits: action.payload,
      };
    }

    default:
      return state;
  }
};

export default investmentAmount;
