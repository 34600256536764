import {
  FETCH_ABOUT_STRATEGY_SUCCESS,
  RESET_ABOUT_STRATEGY,
  FETCH_MIXER_SUMMARY_SUCCESS,
  FETCH_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_PAST_STRATEGIES_SUCCESS,
  FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS,
  FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS,
  FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS,
  FETCH_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS,
  FETCH_STRATEGY_TRADE_HISTORY_SUCCESS,
  FETCH_STRATEGIES_LIST_SUCCESS,
  FETCH_PRO_MESSAGES_COUNT_SUCCESS,
  FETCH_PRO_TOP_EXPERTS_SUCCESS,
  FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS,
  FETCH_PRO_MESSAGES_LIST_SUCCESS,
  GET_WALLET_BALANCE_SUCCESS,
  GET_CREDITS_STATEMENT_SUCCESS,
  GET_BUY_USDT_ONRAMP_STATUS_SUCCESS,
  RESET_ACTIVE_STRATEGY_DETAILS,
  GET_HMM_SUMMARY_SUCCESS,
  RESET_ACTIVE_STRATEGIES,
  RESET_STRATEGIES_DATA,
  RELOAD_AVAILABLE_BALANCE_SUCCESS,
  STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS,
  GET_WALLET_ADDRESS_SUCCESS,
  FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS,
  FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS,
  FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS,
  FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS,
} from "./types";

const initialState = {
  algoTradesActiveStrategiesLoaded: false,
  algoTradesActiveStrategies: {},
  portfoliosActiveStrategiesLoaded: false,
  portfoliosActiveStrategies: {},
  aboutStrategyLoaded: false,
  aboutStrategy: {},
  activeStrategiesLoaded: false,
  activeStrategies: [],
  pastStrategiesLoaded: false,
  pastStrategies: {},
  algoTradesPastStrategiesLoaded: false,
  algoTradesPastStrategies: {},
  algoTradesAvailableStrategies: {},
  algoTradesAvailableStrategiesLoaded: false,

  portfoliosPastStrategiesLoaded: false,
  portfoliosPastStrategies: {},
  portfoliosAvailableStrategies: {},
  portfoliosAvailableStrategiesLoaded: false,
  unSubscribedStrategy: {},
  unSubscribedStrategyLoaded: false,
  availableStrategiesLoaded: false,
  availableStrategies: {},
  usersAvailableStrategies: null,
  usersAvailableStrategiesLoaded: false,
  mixerSummaryLoaded: false,
  mixerSummary: {},
  algoTradesMixerSummaryLoaded: false,
  algoTradesMixerSummary: {},
  portfoliosMixerSummaryLoaded: false,
  portfoliosMixerSummary: {},
  activeStrategyDetailsLoaded: false,
  activeStrategyDetails: [],

  strategyTradeHistoryLoaded: false,
  strategyTradeHistory: {},
  strategiesListLoaded: false,
  strategiesList: {},
  proMessagesCountLoaded: false,
  proMessagesCount: 0,
  proMessagesListLoaded: false,
  proMessagesList: {},
  topExpertsPublicLoaded: false,
  topExpertsPublic: null,
  pastStrategyStatements: null,
  pastStrategyStatementsLoaded: false,
  walletBalance: null,
  walletBalanceLoaded: false,
  creditStatementLoaded: false,
  creditStatement: null,
  buyUsdtOnrampStatus: null,
  buyUsdtOnrampStatusLoaded: false,

  hmmSummary: null,
  hmmSummaryLoaded: false,
  reloadAvailableBalance: null,
  reloadAvailableBalanceLoaded: false,
  strategyAdditionalAvailableBalance: null,
  strategyAdditionalAvailableBalanceLoaded: false,
  getWalletAddress: null,
  getWalletAddressLoaded: false,
};

const mixer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STRATEGIES_DATA: {
      return {
        ...state,
        activeStrategiesLoaded: false,
        activeStrategies: [],
        availableStrategiesLoaded: false,
        availableStrategies: {},
        usersAvailableStrategiesLoaded: false,
        usersAvailableStrategies: {},
        hmmSummary: null,
        hmmSummaryLoaded: false,
        activeStrategyDetailsLoaded: false,
        activeStrategyDetails: [],
        reloadAvailableBalance: null,
        reloadAvailableBalanceLoaded: false,
        strategyAdditionalAvailableBalance: null,
        strategyAdditionalAvailableBalanceLoaded: false,
        getWalletAddress: null,
        getWalletAddressLoaded: false,
      };
    }
    case FETCH_ABOUT_STRATEGY_SUCCESS: {
      return {
        ...state,
        aboutStrategyLoaded: true,
        aboutStrategy: action.payload,
      };
    }

    case RESET_ABOUT_STRATEGY: {
      return {
        ...state,
        aboutStrategyLoaded: false,
        aboutStrategy: {},
      };
    }

    case FETCH_ALGO_TRADES_MIXER_SUMMARY_SUCCESS: {
      return {
        ...state,
        algoTradesMixerSummaryLoaded: true,
        algoTradesMixerSummary: action.payload,
      };
    }
    case FETCH_PORTFOLIOS_MIXER_SUMMARY_SUCCESS: {
      return {
        ...state,
        portfoliosMixerSummaryLoaded: true,
        portfoliosMixerSummary: action.payload,
      };
    }
    case FETCH_MIXER_SUMMARY_SUCCESS: {
      return {
        ...state,
        mixerSummaryLoaded: true,
        mixerSummary: action.payload,
      };
    }
    case FETCH_ALGO_TRADES_ACTIVE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        algoTradesActiveStrategiesLoaded: true,
        algoTradesActiveStrategies: action.payload,
      };
    }
    case FETCH_PORTFOLIOS_ACTIVE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        portfoliosActiveStrategiesLoaded: true,
        portfoliosActiveStrategies: action.payload,
      };
    }
    case FETCH_ACTIVE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        activeStrategiesLoaded: true,
        activeStrategies: action.payload,
      };
    }

    case FETCH_ALGO_TRADES_PAST_STRATEGIES_SUCCESS: {
      return {
        ...state,
        algoTradesPastStrategiesLoaded: true,
        algoTradesPastStrategies: action.payload,
      };
    }
    case FETCH_PORTFOLIOS_PAST_STRATEGIES_SUCCESS: {
      return {
        ...state,
        portfoliosPastStrategiesLoaded: true,
        portfoliosPastStrategies: action.payload,
      };
    }

    case FETCH_UNSUBSCRIBED_STRATEGIES_SUCCESS: {
      return {
        ...state,
        unSubscribedStrategyLoaded: true,
        unSubscribedStrategy: action.payload,
      };
    }

    case FETCH_PAST_STRATEGIES_SUCCESS: {
      return {
        ...state,
        pastStrategiesLoaded: true,
        pastStrategies: action.payload,
      };
    }

    case FETCH_ACTIVE_STRATEGY_DETAILS_SUCCESS: {
      return {
        ...state,
        activeStrategyDetails: action.payload,
        activeStrategyDetailsLoaded: true,
      };
    }

    case RESET_ACTIVE_STRATEGY_DETAILS: {
      return {
        ...state,
        activeStrategyDetailsLoaded: false,
        activeStrategyDetails: null,
      };
    }

    case RESET_ACTIVE_STRATEGIES: {
      return {
        ...state,
        activeStrategies: [],
        activeStrategiesLoaded: false,
      };
    }

    case FETCH_ALGO_TRADES_AVAILABLE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        algoTradesAvailableStrategiesLoaded: true,
        algoTradesAvailableStrategies: action.payload,
      };
    }

    case FETCH_PORTFOLIOS_AVAILABLE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        portfoliosAvailableStrategiesLoaded: true,
        portfoliosAvailableStrategies: action.payload,
      };
    }

    case FETCH_USERS_AVAILABLE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        usersAvailableStrategiesLoaded: true,
        usersAvailableStrategies: action.payload,
      };
    }
    case FETCH_AVAILABLE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        availableStrategiesLoaded: true,
        availableStrategies: action.payload,
      };
    }

    case FETCH_STRATEGY_TRADE_HISTORY_SUCCESS: {
      return {
        ...state,
        strategyTradeHistoryLoaded: true,
        strategyTradeHistory: action.payload,
      };
    }

    case FETCH_STRATEGIES_LIST_SUCCESS: {
      return {
        ...state,
        strategiesListLoaded: true,
        strategiesList: action.payload,
      };
    }

    case FETCH_PRO_MESSAGES_COUNT_SUCCESS: {
      return {
        ...state,
        proMessagesCountLoaded: true,
        proMessagesCount: action.payload,
      };
    }
    case FETCH_PRO_MESSAGES_LIST_SUCCESS: {
      return {
        ...state,
        proMessagesListLoaded: true,
        proMessagesList: action.payload,
      };
    }

    case FETCH_PRO_TOP_EXPERTS_SUCCESS: {
      return {
        ...state,
        topExpertsPublicLoaded: true,
        topExpertsPublic: action.payload,
      };
    }

    case FETCH_PAST_STRATEGY_STATEMENTS_SUCCESS: {
      return {
        ...state,
        pastStrategyStatementsLoaded: true,
        pastStrategyStatements: action.payload,
      };
    }

    case GET_WALLET_BALANCE_SUCCESS: {
      return {
        ...state,
        walletBalanceLoaded: true,
        walletBalance: action.payload,
      };
    }

    case GET_CREDITS_STATEMENT_SUCCESS: {
      return {
        ...state,
        creditStatementLoaded: true,
        creditStatement: action.payload,
      };
    }

    case GET_BUY_USDT_ONRAMP_STATUS_SUCCESS: {
      return {
        ...state,
        buyUsdtOnrampStatusLoaded: true,
        buyUsdtOnrampStatus: action.payload,
      };
    }

    case GET_HMM_SUMMARY_SUCCESS: {
      return {
        ...state,
        hmmSummary: action.payload,
        hmmSummaryLoaded: true,
      };
    }

    case RELOAD_AVAILABLE_BALANCE_SUCCESS: {
      return {
        ...state,
        reloadAvailableBalanceLoaded: true,
        reloadAvailableBalance: action.payload,
      };
    }
    case STRATEGY_ADDITIONAL_AVAILABLE_BALANCE_SUCCESS: {
      return {
        ...state,
        strategyAdditionalAvailableBalanceLoaded: true,
        strategyAdditionalAvailableBalance: action.payload,
      };
    }
    case GET_WALLET_ADDRESS_SUCCESS: {
      return {
        ...state,
        getWalletAddressLoaded: true,
        getWalletAddress: action.payload,
      };
    }

    default:
      return state;
  }
};

export default mixer;
