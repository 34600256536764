export const LOGIN = "app/LOGIN";
export const LOGIN_FAILURE = "app/LOGIN_FAILURE";
export const LOGIN_SUCCESS = "app/LOGIN_SUCCESS";
export const SEND_OTP = "app/SEND_OTP";

export const WHITELIST_USER_TO_PRO = "pro/WHITELIST_USER_TO_PRO";

export const CHANGE_PASSWORD = "app/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "app/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "app/CHANGE_PASSWORD_FAILURE";

export const FETCH_USER_PROFILE = "app/FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_SUCCESS = "app/FETCH_USER_PROFILE_SUCCESS";

export const FETCH_ACTIVE_MEMBERSHIP_DETAILS =
  "app/FETCH_ACTIVE_MEMBERSHIP_DETAILS";
export const FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS =
  "app/FETCH_ACTIVE_MEMBERSHIP_DETAILS_SUCCESS";

export const UPDATE_USER_PROFILE = "app/UPDATE_USER_PROFILE";

export const SET_LAST_ONBOARDING_STATE = "app/constSET_LAST_ONBOARDING_STATE";

export const FETCH_CURRENT_ONBOARDING_STATE =
  "app/constSET_CURRENT_ONBOARDING_STATE";
export const FETCH_CURRENT_ONBOARDING_STATE_SUCCESS =
  "FETCH_CURRENT_ONBOARDING_STATE_SUCCESS";

export const CONNECT_EXCHANGE = "pro/CONNECT_EXCHANGE";

export const FETCH_EXCHANGES = "pro/FETCH_EXCHANGES";
export const CHECK_EXCHANGE_PERMISSIONS = "pro/CHECK_EXCHANGE_PERMISSIONS";

export const LOGIN_WITH_TELEGRAM = "tel/LOGIN_WITH_TELEGRAM";
export const LOGIN_WITH_TELEGRAM_SUCCESS = "tel/LOGIN_WITH_TELEGRAM_SUCCESS";

export const IS_EXCHANGE_CONNECTED = "auth/IS_EXCHANGE_CONNECTED";
export const IS_EXCHANGE_CONNECTED_SUCCESS =
  "auth/IS_EXCHANGE_CONNECTED_CONNECTED";

export const GET_MEMBERSHIP_PAY_LINKS = "pay/GET_MEMBERSHIP_PAY_LINKS";
export const GET_MEMBERSHIP_PAY_LINKS_SUCCESS =
  "pay/GET_MEMBERSHIP_PAY_LINKS_SUCCESS";

export const FETCH_CREDITS_CALCULATION = "credits/FETCH_CREDITS_CALCULATION";
export const FETCH_CREDITS_CALCULATION_SUCCESS =
  "credits/FETCH_CREDITS_CALCULATION_SUCCESS";

export const SET_CREDITS_ORDER = "credits/SET_CREDITS_ORDER";
export const SET_CREDITS_ORDER_SUCCESS = "credits/SET_CREDITS_ORDER_SUCCESS";

export const FETCH_MONTHLY_REPORTS = "reports/FETCH_MONTHLY_REPORTS";
export const FETCH_MONTHLY_REPORTS_SUCCESS =
  "reports/FETCH_MONTHLY_REPORTS_SUCCESS";

export const RESET_MONTHLY_REPORT_DETAILS =
  "reports/RESET_MONTHLY_REPORT_DETAILS";

export const FETCH_MONTHLY_REPORT_DETAILS =
  "reports/FETCH_MONTHLY_REPORT_DETAILS";
export const FETCH_MONTHLY_REPORT_DETAILS_SUCCESS =
  "reports/FETCH_MONTHLY_REPORT_DETAILS_SUCCESS";

export const USER_LOGOUT = "auth/USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "auth/USER_LOGOUT_SUCCESS";
