import { GET_ASSETS_COMPARISON_SUCCESS } from "./types";

const initialState = {
  assetsComparison: null,
  assetsComparisonLoaded: false,
};

const systematicInvestingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETS_COMPARISON_SUCCESS: {
      return {
        ...state,
        assetsComparison: action.payload,
        assetsComparisonLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default systematicInvestingReducer;
