import React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";

const ScWrapper = styled.div``;

const LottieFile = ({ options = {}, media, extraClass }) => {
  return (
    <ScWrapper className={extraClass}>
      <Lottie
        animationData={media}
        options={{
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
          ...options,
        }}
        height={"60%"}
        width={"100%"}
      />
    </ScWrapper>
  );
};

export default LottieFile;
